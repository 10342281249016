import { DESTROY_SESSION, UPDATE_SESSION, UserActionTypes, UserState } from "./types"

/**
 * Create a initial state with all the information in None (null in TS)
 * */
const initialState: UserState = {
  name: null,
  email: null,
  loggedIn: false,
  permission: 0,
  codeState: null,
  codeVerifier: null,
  token: null,
  usedCodes: false,
}

/**
 * Create a function that will update the state. It receives a state and returns an updated (new) one.
 * */
export function userReducer(state: UserState = initialState, action: UserActionTypes): UserState {
  switch (action.type) {
    case UPDATE_SESSION: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case DESTROY_SESSION: {
      return {
        ...initialState,
      }
    }
    default:
      return state
  }
}
