import React, { useEffect } from "react"
import HTTPClient from "../HTTPClient"
import { destroySession } from "../../store/user/actions"
import { useDispatch } from "react-redux"
import { Redirect } from "react-router-dom"
import queryString from "query-string"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Logout = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(destroySession())
  }, [dispatch])

  console.log("logging out")

  const frontUrl: string = process.env.REACT_APP_FRONTEND_URI

  HTTPClient({
    method: "get",
    url: process.env.REACT_APP_BACKEND_LOGOUT_URL,
  })
    .then(() => {
      const query = {
        client_id: process.env.REACT_APP_CLIENT_ID,
        returnTo: frontUrl,
      }
      window.location.href = process.env.REACT_APP_BACKEND_LOGOUT_URL + "?" + queryString.stringify(query)
    })
    .catch((error) => {
      console.log(`No fue posible cerrar la sesión: ${error}`)
      return (
        <div>
          No fue posible cerrar la sesión. <a href="/">Volver.</a>
        </div>
      )
    })
    .finally(() => {
      window.location.href = frontUrl
    })

  return <Redirect to="/" />
}

export default Logout
