import React, { useEffect, useState } from "react"
import { withStyles, createStyles, makeStyles } from "@mui/styles"
import { Container, Link } from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { useGetElections } from "../../hooks/useQueries"
import { Election, ElectionsRow } from "../../types/types"
import differenceInBusinessDays from "date-fns/differenceInBusinessDays"
import { format } from "date-fns"
import { RootState } from "../../store/rootReducer"
import { UserState } from "../../store/user/types"
import { useSelector } from "react-redux"
import { Theme } from "@mui/material/styles"
import { useTheme } from "@mui/material/styles"

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: theme.evoting.EvColorBlue,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell)

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: useTheme().palette.action.hover,
      },
    },
  })
)(TableRow)

const useStyles = makeStyles(() => ({
  main: {
    marginTop: useTheme().spacing(8),
    marginBottom: useTheme().spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableHeadRow: {
    "& .MuiTableCell-root": {
      color: "white",
    },
  },
}))

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Elections = () => {
  const classes = useStyles()

  const electionsList: Election[] = useGetElections()
  const [rows, setRows] = useState<ElectionsRow[]>([])

  const selectUser = (state: RootState) => state.user
  const user: UserState = useSelector(selectUser)

  useEffect(() => {
    if (electionsList) {
      const newRows: ElectionsRow[] = electionsList.map((election) => {
        return {
          electionId: election._id || "",
          name: election.electionShortName,
          assigned: election.assigned ? "Asignada" : "Sin Asignar",
          state: election.state || "",
          startDate: format(new Date(election.voteDate.startDate), "dd/MM/yyyy"),
          ownerEmail: election.ownerEmail,
          startDays: differenceInBusinessDays(new Date(election.voteDate.startDate), Date.now()),
        }
      })
      console.log("newRows: " + JSON.stringify(newRows))
      setRows(newRows)
    }
  }, [electionsList])

  return (
    <Container component="main" className={classes.main} maxWidth="md">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow className={classes.tableHeadRow}>
              <StyledTableCell>Nombre</StyledTableCell>
              <StyledTableCell align="right">Asignación</StyledTableCell>
              <StyledTableCell align="right">Estado</StyledTableCell>
              <StyledTableCell align="right">Responsable</StyledTableCell>
              <StyledTableCell align="right">Fecha inicio</StyledTableCell>
              <StyledTableCell align="right">Días Inicio</StyledTableCell>
              <StyledTableCell align="right">Completar</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="right">{row.assigned}</StyledTableCell>
                <StyledTableCell align="right">{row.state}</StyledTableCell>
                <StyledTableCell align="right">{row.ownerEmail.split("@")[0]}</StyledTableCell>
                <StyledTableCell align="right">{row.startDate}</StyledTableCell>
                <StyledTableCell align="right">{row.startDays}</StyledTableCell>
                <StyledTableCell align="right">
                  {user.email === row.ownerEmail && row.state === "created" && (
                    <Link href={`/elections/complete-election/${row.electionId}`}>Completar </Link>
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}
