import { createStore } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web

import { rootReducer, RootState } from "./rootReducer"
import { PersistPartial } from "redux-persist/lib/persistReducer"

const persistConfig = {
  key: "root",
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const store = createStore<RootState & PersistPartial, any, any, any>(persistedReducer)
export const persistor = persistStore(store)
