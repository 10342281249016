import AppBar from "@mui/material/AppBar"
import { makeStyles } from "@mui/styles"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link"
import Button from "@mui/material/Button"
import { RootState } from "../../store/rootReducer"
import { UserState } from "../../store/user/types"
import { useSelector } from "react-redux"
import { useTheme } from "@mui/material/styles"

const useStyles = makeStyles(() => ({
  appBar: {
    borderBottom: `1px solid ${useTheme().palette.divider}`,
    background: useTheme().palette.primary.main,
    color: "#FFFFFF",
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    //margin: theme.spacing(1, 1.5),
    margin: "8px 12px !important" as any,
  },
}))

export const Header = () => {
  const classes = useStyles()
  const selectUser = (state: RootState) => state.user
  const user: UserState = useSelector(selectUser)

  const ListElection = () => {
    return (
      <Link variant="button" underline="hover" color="secondary" href="/elections/list" className={classes.link}>
        Votaciones
      </Link>
    )
  }

  const NewElection = () => {
    return (
      <Link variant="button" underline="hover" color="secondary" href="/elections/new" className={classes.link}>
        Nueva Votación
      </Link>
    )
  }

  const LoginButton = () => {
    return (
      <Button href="/login" color="secondary" variant="outlined" className={classes.link}>
        Iniciar sesión
      </Button>
    )
  }

  const LogoutButton = () => {
    return (
      <Button href="/logout" color="secondary" variant="outlined" className={classes.link}>
        Cerrar sesión
      </Button>
    )
  }

  return (
    <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
          Evoting E-Planner
        </Typography>
        <nav>
          {user.name !== null && user.name !== undefined && <NewElection />}
          {user.name !== null && user.name !== undefined && <ListElection />}
        </nav>
        {user.name ? <LogoutButton /> : <LoginButton />}
      </Toolbar>
    </AppBar>
  )
}
