import { theme } from "./evotingTheme"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { Home } from "./components/User/Home"
import { Footer } from "./components/UI/Footer"
import { Header } from "./components/UI/Header"
import CssBaseline from "@mui/material/CssBaseline"
import { Login } from "./components/User/Login"
import Callback from "./components/User/Callback"
import { Operators } from "./components/User/Operators"
import Logout from "./components/User/Logout"
import { Elections } from "./components/Elections/Elections"
import PrivateRoute from "./components/ProtectedRoute"
import { COMMERCIAL, SUPERUSER } from "./permissions"
import { NewElections } from "./components/Elections/NewElections"
import { CompleteElections } from "./components/Elections/CompleteElections"
import { makeStyles } from "@mui/styles"
import { ThemeProvider } from "@mui/material/styles"
import { Theme } from "@mui/material/styles"
import { useTheme } from "@mui/material/styles"

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  main: {
    marginTop: useTheme().spacing(8),
    marginBottom: useTheme().spacing(2),
  },
}))

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const App = () => {
  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <Header />
        <Router>
          <Switch>
            <Route path="/callback" component={Callback} exact />
            <Route path="/login" component={Login} exact />
            <Route path="/logout" component={Logout} exact />
            <PrivateRoute path="/elections/list" component={Elections} exact permissions={COMMERCIAL} />
            <PrivateRoute path="/elections/new" component={NewElections} exact permissions={COMMERCIAL} />
            <PrivateRoute path="/elections/complete-election/:electionId" component={CompleteElections} exact permissions={COMMERCIAL} />
            <PrivateRoute path="/operators" component={Operators} exact permissions={SUPERUSER} />
            <Route path="/" component={Home} />
          </Switch>
        </Router>
        <Footer />
      </div>
    </ThemeProvider>
  )
}

export default App
