import React, { useEffect, useState } from "react"
import { ParsedQuery } from "query-string"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store/rootReducer"
import { UserState } from "../../store/user/types"
import { useHistory } from "react-router"
import { updateSession } from "../../store/user/actions"
import { IdTokenDTO, TokenDTO, User } from "../../types/types"
import { usePostToken } from "../../hooks/useQueries"
import { getBearer } from "../../fetchers/fetchers"
import { Link } from "@mui/material"
import queryString from "query-string"

const selectUser = (state: RootState) => state.user

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Callback = () => {
  const storedUser: UserState = useSelector(selectUser)
  const dispatch = useDispatch()

  console.log("callback storedUser: " + JSON.stringify(storedUser))

  const [user, setUser] = useState<User>()

  const qsArgs: ParsedQuery<string> = queryString.parse(window.location.search)
  const history = useHistory()

  console.log(`qsArgs: ${JSON.stringify(qsArgs)}`)

  const post_data: TokenDTO = {
    grant_type: "authorization_code",
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    code: qsArgs.code as string,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    state: storedUser.codeState!,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    code_verifier: storedUser.codeVerifier!,
  }

  console.log("post_data to send: " + JSON.stringify(post_data))
  const tokenReceived: IdTokenDTO | undefined = usePostToken(post_data)

  useEffect(() => {
    console.log("useEffect: storedUser: " + JSON.stringify(storedUser))
  }, [storedUser])

  useEffect(() => {
    console.log("useEffect: user: " + JSON.stringify(user))
  }, [user])

  useEffect(() => {
    console.log("user tokenReceived: " + JSON.stringify(tokenReceived))
    const loginBackend = async (id_token: string) => {
      const info = await getBearer<User>(process.env.REACT_APP_BACKEND_LOGIN_URL, id_token).catch((err) => {
        console.log("ERR: " + err)
        return undefined
      })
      setUser(info)
    }

    if (tokenReceived) {
      loginBackend(tokenReceived.id_token)
    }
  }, [tokenReceived])

  useEffect(() => {
    console.log("user triggered: " + JSON.stringify(user))

    if (user) {
      const updatedUser: UserState = {
        ...storedUser,
        name: user.name,
        email: user.email,
        loggedIn: true,
        usedCodes: true,
        permission: user.role,
      }
      dispatch(updateSession(updatedUser))
      console.log(`pushing /`)
      history.push("/")
    }
  }, [user, dispatch, storedUser, history])

  //history.push("/")
  return <Link href="/">Ir al inicio</Link>
}

export default Callback
