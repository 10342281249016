import axios from "axios"

export async function get<T>(path: string): Promise<T> {
  const axiosInstance = axios.create({
    withCredentials: true,
    headers: { "Content-Type": "application/json" },
    baseURL: process.env.REACT_APP_REACT_APP_FRONTEND_URI,
  })

  const { data } = await axiosInstance.get(path)
  return data
}

export async function getBearer<T>(path: string, bearer: string): Promise<T> {
  const axiosInstance = axios.create({
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearer,
    },
    baseURL: process.env.FRONTEND_URI,
  })

  const { data } = await axiosInstance.get(path)
  return data
}
