// Reference https://redux.js.org/recipes/usage-with-typescript
// Here we define our types,

/**
 * Create an interface (like a class) that represent all the user information related to the login.
 */
export interface UserState {
  name: string | null
  email: string | null
  loggedIn: boolean
  permission: number
  codeState: string | null
  codeVerifier: string | null
  token: string | null
  usedCodes: boolean
}

/**
 * Define all the possible actions for this store. For now we only support one action: Update the session information
 */
export const UPDATE_SESSION = "UPDATE_SESSION"

export const DESTROY_SESSION = "DESTROY_SESSION"

/**
 * Define types for each action. Here we have only one action, ergo one type
 */
interface UpdateSessionAction {
  type: typeof UPDATE_SESSION
  payload: UserState
}

interface DestroySessionAction {
  type: typeof DESTROY_SESSION
}
/**
 * Define all possible actions of the user: eg. UpdateSessionAction | DeleteSessionAction | etc.
 * */
export type UserActionTypes = UpdateSessionAction | DestroySessionAction
