import React from "react"
import { Container, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import Link from "@mui/material/Link"

import version from "../../../package.json"

function Copyright() {
  return (
    <Typography variant="body2">
      {"Solo para uso interno. "}
      <Link color="inherit" href="https://evoting.com/">
        Evoting
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

const useStyles = makeStyles((theme: any) => ({
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: "auto",
    background: theme.palette.primary.main,
    color: "#FFFFFF",
  },
}))

export const Footer = () => {
  const classes = useStyles()
  return (
    <footer className={classes.footer}>
      <Container maxWidth="sm">
        <Typography variant="body1">Version {version.version}</Typography>
        <Copyright />
      </Container>
    </footer>
  )
}
