import { createTheme } from "@mui/material/styles"
import { Theme } from "@mui/material/styles"

declare module "@mui/styles/defaultTheme" {
  type DefaultTheme = Theme
}

declare module "@mui/material/styles" {
  interface Theme {
    evoting: {
      EvColor: React.CSSProperties["color"]
      EvColorGris: React.CSSProperties["color"]
      EvColorLightBlue: React.CSSProperties["color"]
      EvColorBlue: React.CSSProperties["color"]
      EvColorGrisLight: React.CSSProperties["color"]
    }
  }
  interface ThemeOptions {
    evoting: {
      EvColor: React.CSSProperties["color"]
      EvColorGris: React.CSSProperties["color"]
      EvColorLightBlue: React.CSSProperties["color"]
      EvColorBlue: React.CSSProperties["color"]
      EvColorGrisLight: React.CSSProperties["color"]
    }
  }
}

export const theme = createTheme({
  evoting: {
    EvColor: "#FF2800",
    EvColorGris: "#C6CCD4",
    EvColorLightBlue: "#212F3D",
    EvColorBlue: "#0B1224",
    EvColorGrisLight: "#E5ECF0",
  },
  palette: {
    primary: {
      light: "#757ce8",
      main: "#0B1224",
      dark: "#007BFF",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
})
