export const authenticationTypes = [
  { name: "RUT + Nº de Documento", value: "RUT + Nº de Documento" },
  { name: "RUT + Nº de Documento + SMS No Registrado", value: "RUT + Nº de Documento + SMS No Registrado" },
  { name: "RUT + Nº de Documento + SMS Registrado", value: "RUT + Nº de Documento + SMS Registrado" },
  { name: "RUT + Nº de Documento + Preguntas", value: "RUT + Nº de Documento + Preguntas" },
  { name: "Email + Código de 4 dígitos (link)", value: "Email + Código de 4 dígitos (link)" },
  { name: "Rut + Clave enviada al correo", value: "Rut + Clave enviada al correo" },
  { name: "Rut + Nº de Documento + Clave enviada al correo", value: "Rut + Nº de Documento + Clave enviada al correo" },
  { name: "ID + Clave enviada por Correo", value: "ID + Clave enviada por Correo" },
  { name: "ID + Clave enviada por SMS", value: "ID + Clave enviada por SMS" },
  { name: "ID + Clave enviada por Correo y SMS", value: "ID + Clave enviada por Correo y SMS" },
]
export const difusion = [
  { name: "Difusión por EMAIL", value: "Difusión por EMAIL" },
  { name: "Difusión por SMS", value: "Difusión por SMS" },
  { name: "SMS y EMAIL", value: "SMS y EMAIL" },
  { name: "No contempla Difusión", value: "No contempla Difusión" },
]

export const countries = [
  { name: "Chile", value: "Chile" },
  { name: "Costa Rica", value: "Costa Rica" },
  { name: "México", value: "México" },
  { name: "Perú", value: "Perú" },

  { name: "Argentina", value: "Argentina" },

  { name: "Guatemala", value: "Guatemala" },
  { name: "Colombia", value: "Colombia" },
  { name: "Otro", value: "Otro" },
]

export const sellers = [
  { name: "Alejandra Donoso", email: "adonoso@evoting.cl" },
  { name: "Rubén Betancourt", email: "rbetancourt@evoting.cl" },
  { name: "Mario Novoa", email: "mnovoa@evoting.cl" },
  { name: "Felipe Lorca", email: "florca@evoting.cl" },
  { name: "Roberto Llovera", email: "rllovera@evoting.cl" },
  { name: "Jennifer González", email: "jgonzález@evoting.cl" },
  { name: "Gabriela Tijerino", email: "gtijerino@evoting.cl" },
  { name: "Verushka Ugarte", email: "vugarte@evoting.cl" },
  { name: "Mario  Cornejo", email: "mcornejo@evoting.cl" },
]

export const timezones = [
  { name: "America/Adak", value: "America/Adak" },
  { name: "America/Anchorage", value: "America/Anchorage" },
  { name: "America/Anguilla", value: "America/Anguilla" },
  { name: "America/Antigua", value: "America/Antigua" },
  { name: "America/Araguaina", value: "America/Araguaina" },
  { name: "America/Argentina/Buenos_Aires", value: "America/Argentina/Buenos_Aires" },
  { name: "America/Argentina/Catamarca", value: "America/Argentina/Catamarca" },
  { name: "America/Argentina/ComodRivadavia", value: "America/Argentina/ComodRivadavia" },
  { name: "America/Argentina/Cordoba", value: "America/Argentina/Cordoba" },
  { name: "America/Argentina/Jujuy", value: "America/Argentina/Jujuy" },
  { name: "America/Argentina/La_Rioja", value: "America/Argentina/La_Rioja" },
  { name: "America/Argentina/Mendoza", value: "America/Argentina/Mendoza" },
  { name: "America/Argentina/Rio_Gallegos", value: "America/Argentina/Rio_Gallegos" },
  { name: "America/Argentina/Salta", value: "America/Argentina/Salta" },
  { name: "America/Argentina/San_Juan", value: "America/Argentina/San_Juan" },
  { name: "America/Argentina/San_Luis", value: "America/Argentina/San_Luis" },
  { name: "America/Argentina/Tucuman", value: "America/Argentina/Tucuman" },
  { name: "America/Argentina/Ushuaia", value: "America/Argentina/Ushuaia" },
  { name: "America/Aruba", value: "America/Aruba" },
  { name: "America/Asuncion", value: "America/Asuncion" },
  { name: "America/Atikokan", value: "America/Atikokan" },
  { name: "America/Atka", value: "America/Atka" },
  { name: "America/Bahia", value: "America/Bahia" },
  { name: "America/Bahia_Banderas", value: "America/Bahia_Banderas" },
  { name: "America/Barbados", value: "America/Barbados" },
  { name: "America/Belem", value: "America/Belem" },
  { name: "America/Belize", value: "America/Belize" },
  { name: "America/Blanc - Sablon", value: "America/Blanc - Sablon" },
  { name: "America/Boa_Vista", value: "America/Boa_Vista" },
  { name: "America/Bogota", value: "America/Bogota" },
  { name: "America/Boise", value: "America/Boise" },
  { name: "America/Buenos_Aires", value: "America/Buenos_Aires" },
  { name: "America/Cambridge_Bay", value: "America/Cambridge_Bay" },
  { name: "America/Campo_Grande", value: "America/Campo_Grande" },
  { name: "America/Cancun", value: "America/Cancun" },
  { name: "America/Caracas", value: "America/Caracas" },
  { name: "America/Catamarca", value: "America/Catamarca" },
  { name: "America/Cayenne", value: "America/Cayenne" },
  { name: "America/Cayman", value: "America/Cayman" },
  { name: "America/Chicago", value: "America/Chicago" },
  { name: "America/Chihuahua", value: "America/Chihuahua" },
  { name: "America/Coral_Harbour", value: "America/Coral_Harbour" },
  { name: "America/Cordoba", value: "America/Cordoba" },
  { name: "America/Costa_Rica", value: "America/Costa_Rica" },
  { name: "America/Creston", value: "America/Creston" },
  { name: "America/Cuiaba", value: "America/Cuiaba" },
  { name: "America/Curacao", value: "America/Curacao" },
  { name: "America/Danmarkshavn", value: "America/Danmarkshavn" },
  { name: "America/Dawson", value: "America/Dawson" },
  { name: "America/Dawson_Creek", value: "America/Dawson_Creek" },
  { name: "America/Denver", value: "America/Denver" },
  { name: "America/Detroit", value: "America/Detroit" },
  { name: "America/Dominica", value: "America/Dominica" },
  { name: "America/Edmonton", value: "America/Edmonton" },
  { name: "America/Eirunepe", value: "America/Eirunepe" },
  { name: "America/El_Salvador", value: "America/El_Salvador" },
  { name: "America/Ensenada", value: "America/Ensenada" },
  { name: "America/Fort_Nelson", value: "America/Fort_Nelson" },
  { name: "America/Fort_Wayne", value: "America/Fort_Wayne" },
  { name: "America/Fortaleza", value: "America/Fortaleza" },
  { name: "America/Glace_Bay", value: "America/Glace_Bay" },
  { name: "America/Godthab", value: "America/Godthab" },
  { name: "America/Goose_Bay", value: "America/Goose_Bay" },
  { name: "America/Grand_Turk", value: "America/Grand_Turk" },
  { name: "America/Grenada", value: "America/Grenada" },
  { name: "America/Guadeloupe", value: "America/Guadeloupe" },
  { name: "America/Guatemala", value: "America/Guatemala" },
  { name: "America/Guayaquil", value: "America/Guayaquil" },
  { name: "America/Guyana", value: "America/Guyana" },
  { name: "America/Halifax", value: "America/Halifax" },
  { name: "America/Havana", value: "America/Havana" },
  { name: "America/Hermosillo", value: "America/Hermosillo" },
  { name: "America/Indiana/Indianapolis", value: "America/Indiana/Indianapolis" },
  { name: "America/Indiana/Knox", value: "America/Indiana/Knox" },
  { name: "America/Indiana/Marengo", value: "America/Indiana/Marengo" },
  { name: "America/Indiana/Petersburg", value: "America/Indiana/Petersburg" },
  { name: "America/Indiana/Tell_City", value: "America/Indiana/Tell_City" },
  { name: "America/Indiana/Vevay", value: "America/Indiana/Vevay" },
  { name: "America/Indiana/Vincennes", value: "America/Indiana/Vincennes" },
  { name: "America/Indiana/Winamac", value: "America/Indiana/Winamac" },
  { name: "America/Indianapolis", value: "America/Indianapolis" },
  { name: "America/Inuvik", value: "America/Inuvik" },
  { name: "America/Iqaluit", value: "America/Iqaluit" },
  { name: "America/Jamaica", value: "America/Jamaica" },
  { name: "America/Jujuy", value: "America/Jujuy" },
  { name: "America/Juneau", value: "America/Juneau" },
  { name: "America/Kentucky/Louisville", value: "America/Kentucky/Louisville" },
  { name: "America/Kentucky/Monticello", value: "America/Kentucky/Monticello" },
  { name: "America/Knox_IN", value: "America/Knox_IN" },
  { name: "America/Kralendijk", value: "America/Kralendijk" },
  { name: "America/La_Paz", value: "America/La_Paz" },
  { name: "America/Lima", value: "America/Lima" },
  { name: "America/Los_Angeles", value: "America/Los_Angeles" },
  { name: "America/Louisville", value: "America/Louisville" },
  { name: "America/Lower_Princes", value: "America/Lower_Princes" },
  { name: "America/Maceio", value: "America/Maceio" },
  { name: "America/Managua", value: "America/Managua" },
  { name: "America/Manaus", value: "America/Manaus" },
  { name: "America/Marigot", value: "America/Marigot" },
  { name: "America/Martinique", value: "America/Martinique" },
  { name: "America/Matamoros", value: "America/Matamoros" },
  { name: "America/Mazatlan", value: "America/Mazatlan" },
  { name: "America/Mendoza", value: "America/Mendoza" },
  { name: "America/Menominee", value: "America/Menominee" },
  { name: "America/Merida", value: "America/Merida" },
  { name: "America/Metlakatla", value: "America/Metlakatla" },
  { name: "America/Mexico_City", value: "America/Mexico_City" },
  { name: "America/Miquelon", value: "America/Miquelon" },
  { name: "America/Moncton", value: "America/Moncton" },
  { name: "America/Monterrey", value: "America/Monterrey" },
  { name: "America/Montevideo", value: "America/Montevideo" },
  { name: "America/Montreal", value: "America/Montreal" },
  { name: "America/Montserrat", value: "America/Montserrat" },
  { name: "America/Nassau", value: "America/Nassau" },
  { name: "America/New_York", value: "America/New_York" },
  { name: "America/Nipigon", value: "America/Nipigon" },
  { name: "America/Nome", value: "America/Nome" },
  { name: "America/Noronha", value: "America/Noronha" },
  { name: "America/North_Dakota/Beulah", value: "America/North_Dakota/Beulah" },
  { name: "America/North_Dakota/Center", value: "America/North_Dakota/Center" },
  { name: "America/North_Dakota/New_Salem", value: "America/North_Dakota/New_Salem" },
  { name: "America/Nuuk", value: "America/Nuuk" },
  { name: "America/Ojinaga", value: "America/Ojinaga" },
  { name: "America/Panama", value: "America/Panama" },
  { name: "America/Pangnirtung", value: "America/Pangnirtung" },
  { name: "America/Paramaribo", value: "America/Paramaribo" },
  { name: "America/Phoenix", value: "America/Phoenix" },
  { name: "America/Port - au - Prince", value: "America/Port - au - Prince" },
  { name: "America/Port_of_Spain", value: "America/Port_of_Spain" },
  { name: "America/Porto_Acre", value: "America/Porto_Acre" },
  { name: "America/Porto_Velho", value: "America/Porto_Velho" },
  { name: "America/Puerto_Rico", value: "America/Puerto_Rico" },
  { name: "America/Punta_Arenas", value: "America/Punta_Arenas" },
  { name: "America/Rainy_River", value: "America/Rainy_River" },
  { name: "America/Rankin_Inlet", value: "America/Rankin_Inlet" },
  { name: "America/Recife", value: "America/Recife" },
  { name: "America/Regina", value: "America/Regina" },
  { name: "America/Resolute", value: "America/Resolute" },
  { name: "America/Rio_Branco", value: "America/Rio_Branco" },
  { name: "America/Rosario", value: "America/Rosario" },
  { name: "America/Santa_Isabel", value: "America/Santa_Isabel" },
  { name: "America/Santarem", value: "America/Santarem" },
  { name: "America/Santiago", value: "America/Santiago" },
  { name: "America/Santo_Domingo", value: "America/Santo_Domingo" },
  { name: "America/Sao_Paulo", value: "America/Sao_Paulo" },
  { name: "America/Scoresbysund", value: "America/Scoresbysund" },
  { name: "America/Shiprock", value: "America/Shiprock" },
  { name: "America/Sitka", value: "America/Sitka" },
  { name: "America/St_Barthelemy", value: "America/St_Barthelemy" },
  { name: "America/St_Johns", value: "America/St_Johns" },
  { name: "America/St_Kitts", value: "America/St_Kitts" },
  { name: "America/St_Lucia", value: "America/St_Lucia" },
  { name: "America/St_Thomas", value: "America/St_Thomas" },
  { name: "America/St_Vincent", value: "America/St_Vincent" },
  { name: "America/Swift_Current", value: "America/Swift_Current" },
  { name: "America/Tegucigalpa", value: "America/Tegucigalpa" },
  { name: "America/Thule", value: "America/Thule" },
  { name: "America/Thunder_Bay", value: "America/Thunder_Bay" },
  { name: "America/Tijuana", value: "America/Tijuana" },
  { name: "America/Toronto", value: "America/Toronto" },
  { name: "America/Tortola", value: "America/Tortola" },
  { name: "America/Vancouver", value: "America/Vancouver" },
  { name: "America/Virgin", value: "America/Virgin" },
  { name: "America/Whitehorse", value: "America/Whitehorse" },
  { name: "America/Winnipeg", value: "America/Winnipeg" },
  { name: "America/Yakutat", value: "America/Yakutat" },
  { name: "America/Yellowknife", value: "America/Yellowknife" },
  { name: "Brazil/Acre", value: "Brazil/Acre" },
  { name: "Brazil/DeNoronha", value: "Brazil/DeNoronha" },
  { name: "Brazil/East", value: "Brazil/East" },
  { name: "Brazil/West", value: "Brazil/West" },
  { name: "CET", value: "CET" },
  { name: "Chile/Continental", value: "Chile/Continental" },
  { name: "Chile/Isla de Pascua", value: "Chile/EasterIsland" },
  { name: "Cuba", value: "Cuba" },
  { name: "GMT", value: "GMT" },
  { name: "GMT + 0", value: "Etc/GMT + 0" },
  { name: "GMT + 1", value: "Etc/GMT + 1" },
  { name: "GMT + 10", value: "Etc/GMT + 10" },
  { name: "GMT + 11", value: "Etc/GMT + 11" },
  { name: "GMT + 12", value: "Etc/GMT + 12" },
  { name: "GMT + 2", value: "Etc/GMT + 2" },
  { name: "GMT + 3", value: "Etc/GMT + 3" },
  { name: "GMT + 4", value: "Etc/GMT + 4" },
  { name: "GMT + 5", value: "Etc/GMT + 5" },
  { name: "GMT + 6", value: "Etc/GMT + 6" },
  { name: "GMT + 7", value: "Etc/GMT + 7" },
  { name: "GMT + 8", value: "Etc/GMT + 8" },
  { name: "GMT + 9", value: "Etc/GMT + 9" },
  { name: "GMT - 0", value: "Etc/GMT - 0" },
  { name: "GMT - 1", value: "Etc/GMT - 1" },
  { name: "GMT - 10", value: "Etc/GMT - 10" },
  { name: "GMT - 11", value: "Etc/GMT - 11" },
  { name: "GMT - 12", value: "Etc/GMT - 12" },
  { name: "GMT - 13", value: "Etc/GMT - 13" },
  { name: "GMT - 14", value: "Etc/GMT - 14" },
  { name: "GMT - 2", value: "Etc/GMT - 2" },
  { name: "GMT - 3", value: "Etc/GMT - 3" },
  { name: "GMT - 4", value: "Etc/GMT - 4" },
  { name: "GMT - 5", value: "Etc/GMT - 5" },
  { name: "GMT - 6", value: "Etc/GMT - 6" },
  { name: "GMT - 7", value: "Etc/GMT - 7" },
  { name: "GMT - 8", value: "Etc/GMT - 8" },
  { name: "GMT - 9", value: "Etc/GMT - 9" },
  { name: "Europe/Amsterdam", value: "Europe/Amsterdam" },
  { name: "Europe/Andorra", value: "Europe/Andorra" },
  { name: "Europe/Astrakhan", value: "Europe/Astrakhan" },
  { name: "Europe/Athens", value: "Europe/Athens" },
  { name: "Europe/Belfast", value: "Europe/Belfast" },
  { name: "Europe/Belgrade", value: "Europe/Belgrade" },
  { name: "Europe/Berlin", value: "Europe/Berlin" },
  { name: "Europe/Bratislava", value: "Europe/Bratislava" },
  { name: "Europe/Brussels", value: "Europe/Brussels" },
  { name: "Europe/Bucharest", value: "Europe/Bucharest" },
  { name: "Europe/Budapest", value: "Europe/Budapest" },
  { name: "Europe/Busingen", value: "Europe/Busingen" },
  { name: "Europe/Chisinau", value: "Europe/Chisinau" },
  { name: "Europe/Copenhagen", value: "Europe/Copenhagen" },
  { name: "Europe/Dublin", value: "Europe/Dublin" },
  { name: "Europe/Gibraltar", value: "Europe/Gibraltar" },
  { name: "Europe/Guernsey", value: "Europe/Guernsey" },
  { name: "Europe/Helsinki", value: "Europe/Helsinki" },
  { name: "Europe/Isle_of_Man", value: "Europe/Isle_of_Man" },
  { name: "Europe/Istanbul", value: "Europe/Istanbul" },
  { name: "Europe/Jersey", value: "Europe/Jersey" },
  { name: "Europe/Kaliningrad", value: "Europe/Kaliningrad" },
  { name: "Europe/Kiev", value: "Europe/Kiev" },
  { name: "Europe/Kirov", value: "Europe/Kirov" },
  { name: "Europe/Lisbon", value: "Europe/Lisbon" },
  { name: "Europe/Ljubljana", value: "Europe/Ljubljana" },
  { name: "Europe/London", value: "Europe/London" },
  { name: "Europe/Luxembourg", value: "Europe/Luxembourg" },
  { name: "Europe/Madrid", value: "Europe/Madrid" },
  { name: "Europe/Malta", value: "Europe/Malta" },
  { name: "Europe/Mariehamn", value: "Europe/Mariehamn" },
  { name: "Europe/Minsk", value: "Europe/Minsk" },
  { name: "Europe/Monaco", value: "Europe/Monaco" },
  { name: "Europe/Moscow", value: "Europe/Moscow" },
  { name: "Europe/Nicosia", value: "Europe/Nicosia" },
  { name: "Europe/Oslo", value: "Europe/Oslo" },
  { name: "Europe/Paris", value: "Europe/Paris" },
  { name: "Europe/Podgorica", value: "Europe/Podgorica" },
  { name: "Europe/Prague", value: "Europe/Prague" },
  { name: "Europe/Riga", value: "Europe/Riga" },
  { name: "Europe/Rome", value: "Europe/Rome" },
  { name: "Europe/Samara", value: "Europe/Samara" },
  { name: "Europe/San_Marino", value: "Europe/San_Marino" },
  { name: "Europe/Sarajevo", value: "Europe/Sarajevo" },
  { name: "Europe/Saratov", value: "Europe/Saratov" },
  { name: "Europe/Simferopol", value: "Europe/Simferopol" },
  { name: "Europe/Skopje", value: "Europe/Skopje" },
  { name: "Europe/Sofia", value: "Europe/Sofia" },
  { name: "Europe/Stockholm", value: "Europe/Stockholm" },
  { name: "Europe/Tallinn", value: "Europe/Tallinn" },
  { name: "Europe/Tirane", value: "Europe/Tirane" },
  { name: "Europe/Tiraspol", value: "Europe/Tiraspol" },
  { name: "Europe/Ulyanovsk", value: "Europe/Ulyanovsk" },
  { name: "Europe/Uzhgorod", value: "Europe/Uzhgorod" },
  { name: "Europe/Vaduz", value: "Europe/Vaduz" },
  { name: "Europe/Vatican", value: "Europe/Vatican" },
  { name: "Europe/Vienna", value: "Europe/Vienna" },
  { name: "Europe/Vilnius", value: "Europe/Vilnius" },
  { name: "Europe/Volgograd", value: "Europe/Volgograd" },
  { name: "Europe/Warsaw", value: "Europe/Warsaw" },
  { name: "Europe/Zagreb", value: "Europe/Zagreb" },
  { name: "Europe/Zaporozhye", value: "Europe/Zaporozhye" },
  { name: "Europe/Zurich", value: "Europe/Zurich" },
  { name: "Mexico/BajaNorte", value: "Mexico/BajaNorte" },
  { name: "Mexico/BajaSur", value: "Mexico/BajaSur" },
  { name: "Mexico/General", value: "Mexico/General" },
  { name: "Poland", value: "Poland" },
  { name: "Portugal", value: "Portugal" },
  { name: "US/Alaska", value: "US/Alaska" },
  { name: "US/Aleutian", value: "US/Aleutian" },
  { name: "US/Arizona", value: "US/Arizona" },
  { name: "US/Central", value: "US/Central" },
  { name: "US/East - Indiana", value: "US/East - Indiana" },
  { name: "US/Eastern", value: "US/Eastern" },
  { name: "US/Hawaii", value: "US/Hawaii" },
  { name: "US/Indiana - Starke", value: "US/Indiana - Starke" },
  { name: "US/Michigan", value: "US/Michigan" },
  { name: "US/Mountain", value: "US/Mountain" },
  { name: "US/Pacific", value: "US/Pacific" },
  { name: "US/Samoa", value: "US/Samoa" },
  { name: "UTC", value: "UTC" },
]
