import React, { useEffect, useRef, useState } from "react"
import { makeStyles } from "@mui/styles"
import Container from "@mui/material/Container"
import { useParams } from "react-router-dom"
import {
  Candidates,
  ClientDTO,
  Election,
  ElectionFolderDTO,
  ElectoralCommission,
  EventsDTO,
  EVEvent,
  Files,
  FolderStructureDTO,
  IdDTO,
  Operator,
  Person,
  UploadFile,
  UploadFileDTO,
} from "../../types/types"
import { deleteEntity, postEntity, useGetElection, useGetOperators } from "../../hooks/useQueries"
import CssBaseline from "@mui/material/CssBaseline"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import { get } from "../../fetchers/fetchers"
import { Box, IconButton, Modal, Typography } from "@mui/material"
import DateTimePicker from "@mui/lab/DateTimePicker"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select/Select"
import MenuItem from "@mui/material/MenuItem"
import DatePicker from "@mui/lab/DatePicker"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import esLocale from "date-fns/locale/es"
import Button from "@mui/material/Button"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import { Theme } from "@mui/material/styles"
import { SelectChangeEvent } from "@mui/material/Select"
import * as constants from "./const"
import { useHistory } from "react-router"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import { CloudUpload, PersonAdd, PersonRemove } from "@mui/icons-material"
import { Autocomplete } from "@mui/material"
import { sellers, timezones } from "./const"
import { RootState } from "../../store/rootReducer"
import { UserState } from "../../store/user/types"
import { useSelector } from "react-redux"
import { useTheme } from "@mui/material/styles"
import ObjectID from "bson-objectid"

const useStyles = makeStyles(() => ({
  paper: {
    marginTop: useTheme().spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: useTheme().spacing(1),
    backgroundColor: useTheme().palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: useTheme().spacing(3),
    marginBottom: useTheme().spacing(3),
  },
  submit: {
    margin: useTheme().spacing(3, 0, 2),
  },
  formControl: {
    minWidth: "100%",
  },
  paperError: {
    marginTop: useTheme().spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  blockBasic: {
    backgroundColor: "#ffffff",
    color: "black",
  },
  blockContact: {
    backgroundColor: "#ffffff",
    color: "black",
  },
  blockCommission: {
    backgroundColor: "#ffffff",
    color: "black",
  },
  blockConfiguration: {
    backgroundColor: "#ffffff",
    color: "black",
  },
  blockImportantDates: {
    backgroundColor: "#ffffff",
    color: "black",
  },
  blockFiles: {
    backgroundColor: "#ffffff",
    color: "black",
  },
  blockComments: {
    backgroundColor: "#ffffff",
    color: "black",
  },
}))

const modalStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const CompleteElections = () => {
  const classes = useStyles()

  const history = useHistory()
  const selectUser = (state: RootState) => state.user
  const user: UserState = useSelector(selectUser)

  const [maybeClient, setMaybeClient] = useState<ClientDTO>()
  const [tryOut, setTryOut] = useState<boolean>(false)
  const [seller, setSeller] = useState(sellers.find((seller) => seller.email === user.email) || null)
  const [timezone, setTimezone] = useState({ name: "America/Santiago", value: "America/Santiago" })
  const [operator, setOperator] = useState<Operator>({
    nameOperator: "NO ASIGNADO",
    emailOperator: "NO ASIGNADO",
    _id: new ObjectID(),
    weight: -1,
  })

  const [open, setOpen] = React.useState(false)
  const handleClose = () => setOpen(false)

  const [modalText, setModalText] = React.useState("Guardando...")

  const [formValues, setFormValues] = React.useState<Election>({
    _id: new ObjectID(),
    clientFullName: "",
    clientShortName: "",
    electionShortName: "",
    electionType: "",
    clientType: "",
    ownerEmail: "",
    country: "",
    electoralRollSize: 0,
    assigned: false,
    operator: { nameOperator: "NO ASIGNADO", emailOperator: "NO ASIGNADO", _id: new ObjectID(), weight: -1 },
    state: "",
    idPipedrive: "",
    voteDate: {
      eventType: "voteDate",
      name: "",
      timezone: "",
      startDate: new Date(),
      endDate: new Date(new Date().setDate(new Date().getDate() + 1)), // tomorrow
      allDay: true,
    },
    creationDate: new Date(),
    inspector: false,
    notary: false,
    districts: false,
    newClient: false,
    timezone: "",
    ipLimit: 0,
    coordinatorName: "",
    coordinatorEmail: "",
    coordinatorPhone: "",
    electoralCommission: [
      {
        nameCommission: "",
        emailCommission: "",
        phoneCommission: "",
      },
    ],
    inspectorNotaryName: "",
    inspectorNotaryEmail: "",
    inspectorNotaryPhone: "",
    nameElection: "",
    urlElection: "",
    numberOfMarks: 0,
    candidates: [
      {
        nameCandidate: "",
        districtCandidate: "",
      },
    ],
    authMethod: "",
    diffusion: "",

    passwordSendDate: {
      eventType: "passwordSendDate",
      name: "Envío claves ",
      timezone: "",
      startDate: new Date(),
      endDate: new Date(new Date().setDate(new Date().getDate() + 1)), // tomorrow
      allDay: false,
    },

    keyGenerationDate: {
      eventType: "keyGenerationDate",
      name: "GEN ",
      timezone: "",
      startDate: new Date(),
      endDate: new Date(new Date().setDate(new Date().getDate() + 1)), // tomorrow
      allDay: true,
    },
    keyGenerationPlace: "",
    voteCountingDate: {
      eventType: "voteCountingDate",
      name: "ESC ",
      timezone: "",
      startDate: new Date(),
      endDate: new Date(new Date().setDate(new Date().getDate() + 1)), // tomorrow
      allDay: false,
    },
    voteCountingPlace: "",
    keyGenerationTryOutDate: {
      eventType: "keyGenerationTryOutDate",
      name: "GEN Simulacro ",
      timezone: "",
      startDate: new Date(),
      endDate: new Date(new Date().setDate(new Date().getDate() + 1)), // tomorrow
      allDay: true,
    },
    voteCountingTryOutDate: {
      eventType: "voteCountingTryOutDate",
      name: "ESC Simulacro ",
      timezone: "",
      startDate: new Date(),
      endDate: new Date(new Date().setDate(new Date().getDate() + 1)), // tomorrow
      allDay: true,
    },
    comments: "",
  })

  useEffect(() => {
    if (formValues.forceSave) {
      console.log(`formValues SAVING: ${JSON.stringify(formValues)}`)
      const { forceSave, ...formToSend } = formValues
      saveCompleteElection(formToSend)
        .then(() => setFormValues(formToSend))
        .catch((error) => console.log(`error when trying to save: ${error}`))
    }
  }, [formValues])

  const operatorList: Operator[] = useGetOperators()

  const refInputFile = useRef<HTMLInputElement>(null)

  const saveCompleteElection = async (postData: Election) => {
    await postEntity(postData, process.env.REACT_APP_BACKEND_ELECTIONS_URL)
  }

  const { electionId } = useParams<{ electionId: string }>()

  const maybeElection: Election | undefined = useGetElection(electionId)

  // ELECTORAL COMMISSION
  const [electoralCommission, setElectoralCommission] = React.useState<ElectoralCommission[]>([
    {
      nameCommission: "",
      emailCommission: "",
      phoneCommission: "",
    },
  ])

  useEffect(() => {
    console.log(`electoralCommission: ${JSON.stringify(electoralCommission)}`)
    if (electoralCommission) {
      console.log(`setting electoralCommission to ${JSON.stringify(electoralCommission)}`)
      setFormValues({ ...formValues, electoralCommission: electoralCommission })
    }
  }, [electoralCommission])

  const handleCommissionInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const listCommission = [...electoralCommission]
    const { value } = event.target
    const name = event.target.name as keyof ElectoralCommission
    listCommission[index][name] = value
    setElectoralCommission(listCommission)
  }
  const handleRemoveCommission = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    event.preventDefault()
    const listCommission = [...electoralCommission]
    listCommission.splice(index, 1)
    setElectoralCommission(listCommission)
  }
  const handleAddCommission = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const newvalue = [
      ...electoralCommission,
      {
        nameCommission: "",
        emailCommission: "",
        phoneCommission: "",
      },
    ]
    setElectoralCommission(newvalue)
  }

  // CANDIDATES
  const [candidates, setCandidates] = React.useState<Candidates[]>([
    {
      nameCandidate: "",
      districtCandidate: "",
    },
  ])

  useEffect(() => {
    if (candidates) {
      console.log(`setting candidates to ${JSON.stringify(candidates)}`)
      setFormValues({ ...formValues, candidates: candidates })
    }
  }, [candidates])

  const handleCandidateInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, countCandidate: number) => {
    const listCandidate = [...candidates]
    const { value } = event.target
    const name = event.target.name as keyof Candidates
    listCandidate[countCandidate][name] = value
    setCandidates(listCandidate)
  }

  const handleRemoveCandidate = (event: React.MouseEvent<HTMLButtonElement>, countCandidate: number) => {
    event.preventDefault()
    const listCandidate = [...candidates]
    listCandidate.splice(countCandidate, 1)
    setCandidates(listCandidate)
  }

  const handleAddCandidate = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const newCandidate = [...candidates, { nameCandidate: "", districtCandidate: "" }]
    setCandidates(newCandidate)
  }

  const deleteFile = (event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>, googleId: string) => {
    event.preventDefault()
    console.log(`deleteFile: ${googleId}`)
    const payload = { googleDriveId: googleId }

    deleteEntity<typeof payload, IdDTO>(payload, process.env.REACT_APP_BACKEND_DELETE_FILE).then((deletedId) => {
      if (deletedId) {
        console.log(`formValues.files: ${JSON.stringify(formValues.files)}`)
        const deletedFileId = deletedId.id
        const currentFiles = formValues.files?.filter((file) => file.gDriveId !== deletedFileId)
        console.log(`currentFiles : ${JSON.stringify(currentFiles)}`)
        if (currentFiles) {
          setFormValues((currentValues) => ({ ...currentValues, files: currentFiles, forceSave: true }))
        }
      }
    })
  }

  const [fileToUpload, setFileToUpload] = React.useState<UploadFile>()

  useEffect(() => {
    console.log(`file to upload: ${JSON.stringify(fileToUpload)}`)
  }, [fileToUpload])

  const handleFilesFiletype = (event: SelectChangeEvent<string>) => {
    setFileToUpload((currentFile) => ({ ...currentFile, filetype: event.target.value }))
  }

  const handleFilesInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0]
      setFileToUpload((currentFile) => ({ ...currentFile, filename: file.name, file: file }))
    }
  }

  const fileTypes = [
    { name: "Padrón", value: "padron" },
    { name: "Logo", value: "logo" },
    { name: "Landing", value: "landing" },
  ]

  const updateShortNameInDates = (election: Election, name: string) => {
    return Object.assign(election, {
      ...election,
      voteDate: { ...election.voteDate, name: election.voteDate.name + name },
      passwordSendDate: { ...election.passwordSendDate, name: election.passwordSendDate?.name + name },
      keyGenerationDate: { ...election.keyGenerationDate, name: election.keyGenerationDate?.name + name },
      voteCountingDate: { ...election.voteCountingDate, name: election.voteCountingDate?.name + name },
      keyGenerationTryOutDate: { ...election.keyGenerationTryOutDate, name: election.keyGenerationTryOutDate?.name + name },
      voteCountingTryOutDate: { ...election.voteCountingTryOutDate, name: election.voteCountingTryOutDate?.name + name },
    })
  }

  useEffect(() => {
    if (maybeElection) {
      console.log(`maybeElection: ${JSON.stringify(maybeElection)}`)
      console.log(`formValues: ${JSON.stringify(formValues)}`)
      // maybeElection is the election from the DB
      // updatedElection is the merge between formValues and maybeElection
      const electionWithDates = updateShortNameInDates(formValues, maybeElection.electionShortName)
      console.log(`electionWithDates: ${JSON.stringify(electionWithDates)}`)
      const updatedElection = Object.assign(electionWithDates, maybeElection)
      console.log(`updatedElection: ${JSON.stringify(updatedElection)}`)
      setFormValues(updatedElection)
      if (updatedElection.seller) {
        setSeller(updatedElection.seller)
      }
      if (updatedElection.timezone) {
        setTimezone(
          timezones.find((tz) => tz.value === updatedElection.timezone) || { name: "America/Santiago", value: "America/Santiago" }
        )
      }
    }
  }, [maybeElection])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    console.log("name : " + name + "value: " + value)
    setFormValues((currentValue) => ({ ...currentValue, [name]: value }))
  }

  const handleTimezoneInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    console.log("name : " + name + "value: " + value)
    const newTimezone = timezones.find((tz) => tz.name === value) || timezone
    setFormValues((currentValue) => ({ ...currentValue, timezone: newTimezone.value }))
  }
  const handleTimezoneAutoCompleteChange = (event: React.SyntheticEvent, value: { name: string; value: string } | null) => {
    console.log(`setting timezone: :${JSON.stringify(value)}`)
    if (value) {
      setTimezone(value)
      setFormValues((currentValue) => ({ ...currentValue, timezone: value.value }))
    }
  }

  const handleSellerAutoCompleteChange = (event: React.SyntheticEvent, value: { name: string; email: string } | null) => {
    console.log(`setting seller: :${JSON.stringify(value)}`)
    if (value) {
      setSeller(value)
      setFormValues((currentValue) => ({ ...currentValue, seller: value }))
    }
  }

  const handleCountryChange = (event: SelectChangeEvent<string>) => {
    setFormValues({ ...formValues, ["country"]: event.target.value as string })
  }

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target
    console.log("name : " + name + "value: " + event.target.checked)
    setFormValues({ ...formValues, [name]: event.target.checked })
  }

  const handleTryOutCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setFormValues((currentValues) => ({ ...currentValues, tryOutDate: new Date() }))
    } else {
      setFormValues((currentValues) => ({ ...currentValues, tryOutDate: null }))
    }

    setTryOut(event.target.checked)
  }

  const handleAuthChange = (event: SelectChangeEvent<string>) => {
    setFormValues({ ...formValues, ["authMethod"]: event.target.value as string })
  }
  const handleDiffusionChange = (event: SelectChangeEvent<string>) => {
    setFormValues({ ...formValues, ["diffusion"]: event.target.value as string })
  }

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      setFormValues((currentValues) => ({ ...currentValues, voteDate: { ...currentValues.voteDate, startDate: date } }))
    }
  }
  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      setFormValues((currentValues) => ({ ...currentValues, voteDate: { ...currentValues.voteDate, endDate: date } }))
    }
  }

  const addMinutes = (date: Date, minutes: number) => {
    return new Date(date.getTime() + minutes * 60000)
  }

  const handlePasswordSendDateChange = (date: Date | null) => {
    if (date) {
      setFormValues((currentValues) => ({
        ...currentValues,
        passwordSendDate: {
          eventType: "passwordSendDate",
          name: "Envío claves " + currentValues.electionShortName,
          timezone: currentValues.timezone,
          startDate: date,
          endDate: addMinutes(date, 30),
          allDay: false,
          gDriveEventId: currentValues.passwordSendDate?.gDriveEventId,
        },
      }))
    }
  }
  const handleKeyGenerationDateChange = (date: Date | null) => {
    if (date) {
      setFormValues((currentValues) => ({
        ...currentValues,
        keyGenerationDate: {
          eventType: "keyGenerationDate",
          name: "GEN " + currentValues.electionShortName,
          timezone: currentValues.timezone,
          startDate: date,
          endDate: addMinutes(date, 30),
          allDay: false,
          gDriveEventId: currentValues.keyGenerationDate?.gDriveEventId,
        },
      }))
    }
  }
  const handleVoteCountingDateChange = (date: Date | null) => {
    if (date) {
      setFormValues((currentValues) => ({
        ...currentValues,
        voteCountingDate: {
          eventType: "voteCountingDate",
          name: "ESC " + currentValues.electionShortName,
          timezone: currentValues.timezone,
          startDate: date,
          endDate: addMinutes(date, 60),
          allDay: false,
          gDriveEventId: currentValues.voteCountingDate?.gDriveEventId,
        },
      }))
    }
  }

  const handleGenerationTryOutDateChange = (date: Date | null) => {
    if (date) {
      setFormValues((currentValues) => ({
        ...currentValues,
        keyGenerationTryOutDate: {
          eventType: "keyGenerationTryOutDate",
          name: "GEN Simulacro " + currentValues.electionShortName,
          timezone: currentValues.timezone,
          startDate: date,
          endDate: addMinutes(date, 60),
          allDay: false,
          gDriveEventId: currentValues.keyGenerationTryOutDate?.gDriveEventId,
        },
      }))
    }
  }

  const handleCountingTryOutDateChange = (date: Date | null) => {
    if (date) {
      setFormValues((currentValues) => ({
        ...currentValues,
        voteCountingTryOutDate: {
          eventType: "voteCountingTryOutDate",
          name: "ESC Simulacro " + currentValues.electionShortName,
          timezone: currentValues.timezone,
          startDate: date,
          endDate: addMinutes(date, 60),
          allDay: false,
          gDriveEventId: currentValues.voteCountingTryOutDate?.gDriveEventId,
        },
      }))
    }
  }

  const handleOperatorAutoCompleteChange = (event: React.SyntheticEvent, value: Operator | null) => {
    console.log(`setting operator: :${JSON.stringify(value)}`)
    if (value && value.nameOperator != "SIN ASIGNAR") {
      console.log(`setting operator`)
      setOperator(value)
      setFormValues((currentValues) => ({
        ...currentValues,
        operator: value,
        ["assigned"]: Boolean(value),
      }))
    } else if (value && value.nameOperator === "SIN ASIGNAR") {
      setOperator(value)
      console.log(`setting operator to undefined`)
      setFormValues((currentValues) => ({
        ...currentValues,
        operator: undefined,
        ["assigned"]: Boolean(false),
      }))
    }
  }

  // Creates a folder and returns its googleId,
  // If exists, returns the current googleId .
  const createFolderAndSaveFolderId: () => Promise<string> = async () => {
    if (formValues.gDriveFolderId) {
      const folderId: string = formValues.gDriveFolderId || ""
      return new Promise((resolve) => resolve(folderId))
    } else {
      return addElectionFolder({
        name: formValues.clientShortName || formValues.electionShortName,
        country: formValues.country,
        startDate: formValues.voteDate.startDate,
      })
        .then((folderResponse) => {
          if (folderResponse) {
            console.log(`createFolderAndSaveFolderId: folderResponse: ${folderResponse}`)
            const folderId = folderResponse.parentFolderId
            console.log(`createFolderAndSaveFolderId: folderId: ${folderId}`)
            setFormValues((currentValues) => ({ ...currentValues, gDriveFolderId: folderId, forceSave: true }))
            return folderId
          } else {
            throw `Folder creation failed`
          }
        })
        .catch((error) => {
          console.log(`Folder creation failed: ${error}`)
          throw `Folder creation failed: ${error}`
        })
    }
  }

  const createAndSaveEventsInCalendar = async () => {
    const keyGenerationTryOutDate = tryOut ? formValues.keyGenerationTryOutDate : null
    const voteCountingTryOutDate = tryOut ? formValues.voteCountingTryOutDate : null

    const eventsInTheForm: EventsDTO = {
      voteDate: formValues.voteDate,
      passwordSendDate: formValues.passwordSendDate,
      keyGenerationDate: formValues.keyGenerationDate,
      voteCountingDate: formValues.voteCountingDate,
      keyGenerationTryOutDate: keyGenerationTryOutDate,
      voteCountingTryOutDate: voteCountingTryOutDate,
    }

    console.log(`sending  eventsInTheForm: ${JSON.stringify(eventsInTheForm)}`)

    return postEntity<EventsDTO, EventsDTO>(eventsInTheForm, process.env.REACT_APP_BACKEND_CREATE_EVENTS).then((events) => {
      if (events) {
        console.log(`saving the events: ${events}`)
        setFormValues((currentValues) => ({
          ...currentValues,
          voteDate: events.voteDate,
          passwordSendDate: events.passwordSendDate,
          keyGenerationDate: events.keyGenerationDate,
          voteCountingDate: events.voteCountingDate,
          keyGenerationTryOutDate: events.keyGenerationTryOutDate,
          voteCountingTryOutDate: events.voteCountingTryOutDate,
          forceSave: true,
        }))
      }
    })
  }

  const uploadFileButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    createFolderAndSaveFolderId().then((newFolderId) => {
      console.log(`uploadFileButtonHandler folderResponse: ${newFolderId}`)
      if (fileToUpload && fileToUpload.file && fileToUpload.filename && fileToUpload.filetype && newFolderId) {
        const formData = new FormData()
        formData.append("file", fileToUpload.file)
        formData.append("folderId", newFolderId)
        uploadFile(formData).then((uploadedFile) => {
          if (fileToUpload.file && fileToUpload.filename && fileToUpload.filetype && uploadedFile) {
            const newFile: Files = {
              filename: fileToUpload.filename,
              filetype: fileToUpload.filetype,
              gDriveId: uploadedFile.id,
            }
            setFormValues((currentValues) => ({
              ...currentValues,
              files: [...(currentValues.files || []), newFile],
              forceSave: true,
            }))
            setFileToUpload(undefined)
            if (refInputFile && refInputFile.current) refInputFile.current.value = ""
          }
        })
      }
    })
  }

  const completeAddButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setOpen(true)

    if (maybeElection) {
      console.log("set complete")
      setModalText("Creando carpetas...")
      createFolderAndSaveFolderId()
        .then((newFolderId) => {
          console.log(`completeAddButtonHandler newFolderId: ${newFolderId}`)
          setFormValues((currentValues) => ({
            ...currentValues,
            _id: maybeElection._id,
            forceSave: true,
          }))
        })
        .then(() => {
          setModalText("Guardando eventos en el calendario...")
          createAndSaveEventsInCalendar()
        })
        .then(() => {
          history.push("/elections/list")
        })
        .catch((error) => {
          setModalText(`Se ha producido un error: ${error}`)
          console.log(`completeAddButtonHandler error: ${error}`)
        })
    }
  }
  const addElectionFolder = async (postData: ElectionFolderDTO) => {
    return postEntity<ElectionFolderDTO, FolderStructureDTO>(postData, process.env.REACT_APP_BACKEND_CREATE_FOLDER)
  }
  const uploadFile = async (postData: FormData) => {
    return postEntity<FormData, UploadFileDTO>(postData, process.env.REACT_APP_BACKEND_UPLOAD_FILE)
  }
  const createCalendarEvents = async (postData: EventsDTO) => {
    return postEntity(postData, process.env.REACT_APP_BACKEND_CREATE_EVENTS)
  }

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Typography variant="h4" gutterBottom>
        Nueva Votación
      </Typography>
      <form className={classes.form} noValidate>
        <input type="hidden" name="userEmail" id="userEmail" value={formValues.ownerEmail} />
        <Grid container justifyContent="center" spacing={2} className={classes.blockBasic}>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <Autocomplete
                id="operator"
                getOptionLabel={(option) => option.nameOperator}
                isOptionEqualToValue={(option, value) => option.nameOperator === value.nameOperator}
                onChange={handleOperatorAutoCompleteChange}
                value={formValues.operator}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label="Operador"
                    name="operatorName"
                    variant="outlined"
                    fullWidth
                    id="operatorText"
                    autoFocus
                  />
                )}
                options={[...operatorList, { nameOperator: "NO ASIGNADO", emailOperator: "NO ASIGNADO", _id: new ObjectID(), weight: -1 }]}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormControl fullWidth>
              <Autocomplete
                id="seller"
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                onChange={handleSellerAutoCompleteChange}
                value={seller}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label="Vendedor"
                    name="sellerEmail"
                    variant="outlined"
                    fullWidth
                    id="sellerEmail"
                    autoFocus
                  />
                )}
                options={sellers}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box>&nbsp;</Box>
        </Grid>

        {/* Grid Datos Basicos */}
        <Grid container justifyContent="center" spacing={2} className={classes.blockBasic}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Datos básicos
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="ename"
              name="clientName"
              variant="outlined"
              required
              fullWidth
              id="clientName"
              label="Nombre oficial organización"
              autoFocus
              value={formValues.clientFullName}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="sname"
              name="clientShortName"
              variant="outlined"
              required
              fullWidth
              id="clientShortName"
              label="Nombre abreviado de cliente"
              value={formValues.clientShortName}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="namelanding"
              name="nameLanding"
              variant="outlined"
              required
              fullWidth
              id="nameLanding"
              label="Nombre corto votación"
              value={formValues.electionShortName}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <InputLabel id="countryLabel">País</InputLabel>
              <Select labelId="country" id="country" label="País" value={formValues.country} onChange={handleCountryChange}>
                {constants.countries.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {" "}
                    {item.name}{" "}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Autocomplete
                id="autoTimezone"
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                onChange={handleTimezoneAutoCompleteChange}
                value={timezone}
                renderInput={(params) => (
                  <TextField {...params} name="timezone" variant="outlined" fullWidth id="timezone" label="Huso horario" autoFocus />
                )}
                options={timezones}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} />
        </Grid>

        <Grid item xs={12}>
          <Box>&nbsp;</Box>
          <Box>&nbsp;</Box>
        </Grid>

        {/* Grid Contacto */}
        <Grid container justifyContent="center" spacing={2} className={classes.blockContact}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Contacto
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography variant="h6" gutterBottom>
              Coordinador
            </Typography>
            <TextField
              autoComplete=""
              name="coordinatorName"
              variant="outlined"
              required
              fullWidth
              id="coordinatorName"
              label="Nombre"
              value={formValues.coordinatorName}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              autoComplete=""
              name="coordinatorEmail"
              variant="outlined"
              required
              fullWidth
              id="coordinatorEmail"
              label="Email"
              value={formValues.coordinatorEmail}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              autoComplete=""
              name="coordinatorPhone"
              variant="outlined"
              required
              fullWidth
              id="coordinatorPhone"
              label="Teléfono"
              value={formValues.coordinatorPhone}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box>&nbsp;</Box>
        </Grid>

        {formValues.electoralCommission?.map((x, i: number) => {
          return (
            <Grid container justifyContent="center" spacing={2} className={classes.blockCommission} key={"commissionMain" + i}>
              <Grid item xs={12} sm={12} key={"commissionTitleItem" + i}>
                <Typography variant="h6" gutterBottom>
                  Comisión Electoral {i + 1}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} key={"nameCommissionItem" + i}>
                <TextField
                  autoComplete=""
                  name="nameCommission"
                  variant="outlined"
                  required
                  fullWidth
                  id="nameCommission"
                  label="Nombre"
                  value={formValues.electoralCommission?.[i]["nameCommission"]}
                  onChange={(event) => handleCommissionInputChange(event, i)}
                />
              </Grid>
              <Grid item xs={12} sm={6} key={"phoneCommissionItem" + i}>
                <TextField
                  autoComplete=""
                  name="phoneCommission"
                  variant="outlined"
                  required
                  fullWidth
                  id="phoneCommission"
                  label="Teléfono"
                  value={formValues.electoralCommission?.[i]["phoneCommission"]}
                  onChange={(event) => handleCommissionInputChange(event, i)}
                />
              </Grid>
              <Grid item xs={12} sm={10} key={"emailCommissionItem" + i}>
                <TextField
                  autoComplete=""
                  name="emailCommission"
                  variant="outlined"
                  required
                  fullWidth
                  id="emailCommission"
                  label="Email"
                  value={formValues.electoralCommission?.[i]["emailCommission"]}
                  onChange={(event) => handleCommissionInputChange(event, i)}
                />
              </Grid>
              <Grid item xs={12} sm={2} key={"buttonCommissionItem" + i}>
                <Box sx={{ paddingTop: 0 }}>
                  {formValues.electoralCommission?.length !== 1 && (
                    <IconButton onClick={(event) => handleRemoveCommission(event, i)}>
                      <PersonRemove />
                    </IconButton>
                  )}
                  {(formValues.electoralCommission?.length || 0) - 1 === i && (
                    <IconButton onClick={(event) => handleAddCommission(event)}>
                      <PersonAdd />
                    </IconButton>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>&nbsp;</Box>
              </Grid>
            </Grid>
          )
        })}
        <Grid item xs={12}>
          <Box>&nbsp;</Box>
          <Box>&nbsp;</Box>
        </Grid>

        <Grid container justifyContent="center" spacing={2} item xs={12} sm={12}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" gutterBottom>
              Ministro de fe / Notario
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              autoComplete=""
              name="inspectorNotaryName"
              variant="outlined"
              required
              fullWidth
              id="inspectorNotaryName"
              label="Nombre"
              value={formValues.inspectorNotaryName}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              autoComplete=""
              name="inspectorNotaryEmail"
              variant="outlined"
              required
              fullWidth
              id="inspectorNotaryEmail"
              label="Email"
              value={formValues.inspectorNotaryEmail}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              autoComplete=""
              name="inspectorNotaryPhone"
              variant="outlined"
              required
              fullWidth
              id="inspectorNotaryPhone"
              label="Teléfono"
              value={formValues.inspectorNotaryPhone}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item justifyContent="center" xs={5}>
            <Box display="flex" justifyContent="center">
              <FormControlLabel
                control={<Checkbox checked={formValues.inspector} color="primary" onChange={handleCheckChange} name="inspector" />}
                label="Inspección del Trabajo"
              />
            </Box>
          </Grid>

          <Grid item justifyContent="center" xs={4}>
            <Box display="flex" justifyContent="center">
              <FormControlLabel
                control={<Checkbox checked={formValues.notary} color="primary" onChange={handleCheckChange} name="notary" />}
                label="Notario"
              />
            </Box>
          </Grid>

          <Grid item justifyContent="center" xs={3} alignContent="center">
            <Box display="flex" justifyContent="center">
              <FormControlLabel
                control={<Checkbox checked={formValues.districts} color="primary" onChange={handleCheckChange} name="districts" />}
                label="Distritos"
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box>&nbsp;</Box>
          <Box>&nbsp;</Box>
        </Grid>
        <Grid container alignContent="flex-end" spacing={2} className={classes.blockConfiguration}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Configuración
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="nameElection"
              variant="outlined"
              required
              fullWidth
              id="nameElection"
              label="Nombre de la votación"
              value={formValues.nameElection}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              type="number"
              name="numberOfMarks"
              variant="outlined"
              required
              fullWidth
              id="numberOfMarks"
              label="Cantidad de marcas"
              value={formValues.numberOfMarks}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <InputLabel id="authLabel">Autentificación</InputLabel>
              <Select labelId="auth" id="auth" label="metodo" value={formValues.authMethod} onChange={handleAuthChange}>
                {constants.authenticationTypes.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {" "}
                    {item.name}{" "}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <InputLabel id="">Difusión</InputLabel>
              <Select labelId="difusion" id="difusion" label="difusion" value={formValues.diffusion} onChange={handleDiffusionChange}>
                {constants.difusion.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {" "}
                    {item.name}{" "}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="sizeElectoralRoll"
              name="electoralRollSize"
              variant="outlined"
              required
              fullWidth
              id="electoralRollSize"
              label="Padrón"
              value={formValues.electoralRollSize}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="ipLimit"
              name="ipLimit"
              variant="outlined"
              required
              fullWidth
              id="ipLimit"
              label="Límite Ip"
              value={formValues.ipLimit}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="idPipedrive"
              name="idPipedrive"
              variant="outlined"
              required
              fullWidth
              id="idPipedrive"
              label="ID Pipedrive"
              value={formValues.idPipedrive}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} justifyContent="center">
            <Box display="flex" justifyContent="center">
              <FormControlLabel
                control={<Checkbox checked={formValues.newClient} color="primary" onChange={handleCheckChange} name="newClient" />}
                label="Cliente nuevo"
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box>&nbsp;</Box>
          <Box>&nbsp;</Box>
        </Grid>
        {formValues.candidates?.map((x, i: number) => {
          return (
            <Grid container justifyContent="center" spacing={2} className={classes.blockCommission} key={"candidateMain" + i}>
              <Grid item xs={12} sm={12} key={"candidateTitleItem" + i}>
                <Typography variant="h6" gutterBottom>
                  Candidato {i + 1}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5} key={"nameCandidateItem" + i}>
                <TextField
                  name="nameCandidate"
                  variant="outlined"
                  required
                  fullWidth
                  id="nameCandidate"
                  label="Nombre Candidato"
                  value={formValues.candidates?.[i]["nameCandidate"]}
                  onChange={(event) => handleCandidateInputChange(event, i)}
                />
              </Grid>
              <Grid item xs={12} sm={5} key={"districtCandidate" + i}>
                <TextField
                  name="districtCandidate"
                  variant="outlined"
                  required
                  fullWidth
                  id="districtCandidate"
                  label="Distrito de Candidato"
                  value={formValues.candidates?.[i]["districtCandidate"]}
                  onChange={(event) => handleCandidateInputChange(event, i)}
                />
              </Grid>
              <Grid item xs={12} sm={2} key={"buttonCandidate" + i}>
                <Box sx={{ paddingTop: 0 }}>
                  {formValues.candidates?.length !== 1 && (
                    <IconButton onClick={(event) => handleRemoveCandidate(event, i)}>
                      <PersonRemove />
                    </IconButton>
                  )}
                  {(formValues.candidates?.length || 0) - 1 === i && (
                    <IconButton onClick={(event) => handleAddCandidate(event)}>
                      <PersonAdd />
                    </IconButton>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>&nbsp;</Box>
              </Grid>
            </Grid>
          )
        })}
        <Grid container justifyContent="center" spacing={2} className={classes.blockImportantDates}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Fechas relevantes
            </Typography>
          </Grid>
          <Grid container item xs={12} sm={4} justifyContent="space-around">
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <DatePicker
                  label="Fecha inicio"
                  value={formValues.voteDate.startDate}
                  onChange={handleStartDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid container item xs={12} sm={4} justifyContent="space-around">
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <DatePicker
                  label="Fecha de fin"
                  value={formValues.voteDate.endDate}
                  onChange={handleEndDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid container item xs={12} sm={4} justifyContent="space-around">
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <DateTimePicker
                  label="Fecha envío de claves"
                  value={formValues.passwordSendDate?.startDate}
                  onChange={handlePasswordSendDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid container item xs={12} sm={6} justifyContent="space-around">
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <DateTimePicker
                  label="Fecha Generación de llaves"
                  value={formValues.keyGenerationDate?.startDate}
                  onChange={handleKeyGenerationDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="keyGenerationPlace"
              name="keyGenerationPlace"
              variant="outlined"
              required
              fullWidth
              id="keyGenerationPlace"
              label="Lugar Generación de llaves"
              value={formValues.keyGenerationPlace}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid container item xs={12} sm={6} justifyContent="space-around">
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <DateTimePicker
                  label="Fecha Escrutinio"
                  value={formValues.voteCountingDate?.startDate}
                  onChange={handleVoteCountingDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="voteCountingPlace"
              name="voteCountingPlace"
              variant="outlined"
              required
              fullWidth
              id="voteCountingPlace"
              label="Lugar Escrutinio"
              value={formValues.voteCountingPlace}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} justifyContent="center">
            <FormControlLabel
              control={<Checkbox checked={tryOut} color="primary" onChange={handleTryOutCheckChange} name="tryOut" />}
              label="Simulacro"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <DateTimePicker
                  label="Fecha Simulacro GEN"
                  disabled={!tryOut}
                  value={formValues.keyGenerationTryOutDate?.startDate}
                  onChange={handleGenerationTryOutDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} />
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <DateTimePicker
                  label="Fecha Simulacro ESC"
                  disabled={!tryOut}
                  value={formValues.voteCountingTryOutDate?.startDate}
                  onChange={handleCountingTryOutDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box>&nbsp;</Box>
          <Box>&nbsp;</Box>
        </Grid>
        <Grid container justifyContent="center" spacing={2} className={classes.blockFiles}>
          <Grid item xs={12}>
            <Typography variant="h5">Archivos</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box>&nbsp;</Box>
        </Grid>
        {(!formValues.files || formValues.files.length == 0) && (
          <Grid container justifyContent="center" spacing={2} className={classes.blockFiles}>
            <Grid item xs={12}>
              <Typography variant="h6">No hay nuevos archivos subidos al drive</Typography>
            </Grid>
          </Grid>
        )}
        {formValues.files?.map((file, i: number) => {
          return (
            <Grid container justifyContent="center" spacing={2} className={classes.blockCommission} key={"filesMain" + i}>
              <Grid item xs={12} sm={12} key={"fileItem" + i}>
                <Typography variant="h6">
                  Archivo {i + 1}: {file.filetype}
                </Typography>
              </Grid>
              <Grid item xs={1} sm={1} key={"fileDeleteButtonItem" + i}>
                <IconButton
                  onClick={(event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
                    if (window.confirm("Seguro que quieres borrar el archivo?")) {
                      deleteFile(event, file.gDriveId)
                    }
                  }}
                >
                  <DeleteForeverIcon sx={{ color: "red" }} />
                </IconButton>
              </Grid>
              <Grid item xs={11} sm={11} key={"fileFilenameItem" + i}>
                <Box sx={{ paddingTop: 1 }}>
                  <Typography>{file.filename}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>&nbsp;</Box>
              </Grid>
            </Grid>
          )
        })}

        <Grid container justifyContent="center" spacing={2} className={classes.blockFiles}>
          <Grid item xs={12}>
            <Box>&nbsp;</Box>
          </Grid>
        </Grid>

        <Grid container justifyContent="center" spacing={2} className={classes.blockCommission}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" gutterBottom>
              Subir Archivo
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Tipo</InputLabel>
              <Select value={fileToUpload?.filetype || ""} label="Tipo" onChange={(event: SelectChangeEvent) => handleFilesFiletype(event)}>
                {fileTypes.map((item, i: number) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <input type="file" name="file" ref={refInputFile} onChange={(event) => handleFilesInputChange(event)} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <IconButton
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                if (fileToUpload?.file && fileToUpload?.filename && fileToUpload?.filetype) {
                  uploadFileButtonHandler(event)
                } else {
                  window.alert("No has seleccionado el tipo de archivo o el archivo para subir")
                }
              }}
            >
              <CloudUpload />
            </IconButton>
          </Grid>
        </Grid>

        <Grid container justifyContent="center" spacing={2} className={classes.blockComments}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Observaciones
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="comments"
              name="comments"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              id="comments"
              label="Observaciones"
              value={formValues.comments}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} justifyContent="center">
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                if (fileToUpload?.file || fileToUpload?.filename || fileToUpload?.filetype) {
                  if (window.confirm("Hay 1 archivo sin subir. ¿Quieres continuar y descartar el archivo?")) {
                    completeAddButtonHandler(event)
                  } else {
                    event.preventDefault()
                  }
                } else {
                  completeAddButtonHandler(event)
                }
              }}
            >
              COMPLETAR FORMULARIO
            </Button>
          </Grid>
        </Grid>
      </form>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Estado:
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {modalText}
          </Typography>
        </Box>
      </Modal>
    </Container>
  )
}
