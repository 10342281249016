import React, { useEffect, useState } from "react"
import { makeStyles } from "@mui/styles"
import { useTheme } from "@mui/material/styles"
import { RootState } from "../../store/rootReducer"
import { UserState } from "../../store/user/types"
import { useSelector } from "react-redux"
import { Operator } from "../../types/types"
import { useGetElectionTypes, useGetOperators } from "../../hooks/useQueries"
import CssBaseline from "@mui/material/CssBaseline"
import Avatar from "@mui/material/Avatar"
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"

const useStyles = makeStyles(() => ({
  paper: {
    marginTop: useTheme().spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: useTheme().spacing(1),
    backgroundColor: useTheme().palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: useTheme().spacing(3),
    marginBottom: useTheme().spacing(3),
  },
  submit: {
    margin: useTheme().spacing(3, 0, 2),
  },
  formControl: {
    minWidth: "100%",
  },
  paperError: {
    marginTop: useTheme().spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}))

export const Operators = () => {
  const classes = useStyles()
  const selectUser = (state: RootState) => state.user
  const user: UserState = useSelector(selectUser)
  const [operators, setOperators] = useState<Operator[]>()

  const operatorsList: Operator[] = useGetOperators()

  useEffect(() => {
    console.log(`operators: ${JSON.stringify(operatorsList)}`)
    if (operatorsList) setOperators(operatorsList)
  }, [operatorsList])

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <CreateOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h3">
          Nueva votación
        </Typography>
        <div className={classes.paperError} />
        <form className={classes.form} noValidate></form>
      </div>
    </Container>
  )
}
