import crypto from "crypto"

export const base64URLEncode: (str: string) => string = (str: string) => {
  return str.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "")
}

export const sha256: (buffer: string) => Buffer = (buffer: string) => {
  const res = crypto.createHash("sha256").update(buffer).digest()
  return res
}
