import React from "react"
import { createStyles, makeStyles } from "@mui/styles"
import CircularProgress from "@mui/material/CircularProgress"
import { Theme } from "@mui/material/styles"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      "& > * + *": {},
    },
  })
)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress />
      <CircularProgress color="secondary" />
    </div>
  )
}
