import { Box, Container, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { RootState } from "../../store/rootReducer"
import { UserState } from "../../store/user/types"
import { makeStyles } from "@mui/styles"
import { useGetUserInfo } from "../../hooks/useQueries"
import { UserRequest } from "../../types/types"
import { useHistory } from "react-router"
import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTheme } from "@mui/material/styles"

const useStyles = makeStyles(() => ({
  main: {
    marginTop: useTheme().spacing(8),
    marginBottom: useTheme().spacing(2),
  },
}))

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Home = () => {
  // Reading from the store
  const selectUser = (state: RootState) => state.user
  const user: UserState = useSelector(selectUser)
  const history = useHistory()
  const userInformation: UserRequest | undefined = useGetUserInfo()

  const ws = useRef<WebSocket | null>(null)
  const secureWs = useRef<WebSocket | null>(null)

  const [message, setMessage] = useState("")
  const [authMessage, setAuthMessage] = useState("")

  const [readyState, setReadyState] = useState({ normal: "linking", secure: "linking" })

  const stateArr = ["linking", "has been linked and can communicate", "connection closing", "connection closed or no link succeeded"]

  // https://developpaper.com/simple-demo-of-websocket-react/
  // https://www.grapecity.com/blogs/moving-from-react-components-to-react-hooks
  // https://devtrium.com/posts/set-interval-react
  useLayoutEffect(() => {
    ws.current = new WebSocket(`${process.env.REACT_APP_WS_URI}/now`)
    ws.current.onopen = (_e) => setReadyState((currentState) => ({ ...currentState, normal: stateArr[ws.current?.readyState ?? 0] }))
    ws.current.onclose = (_e) => setReadyState((currentState) => ({ ...currentState, normal: stateArr[ws.current?.readyState ?? 0] }))
    ws.current.onerror = (e) => setReadyState((currentState) => ({ ...currentState, normal: stateArr[ws.current?.readyState ?? 0] }))
    ws.current.onmessage = (e) => {
      setMessage(e.data)
    }

    return () => {
      ws.current?.close()
    }
  }, [ws])

  useLayoutEffect(() => {
    secureWs.current = new WebSocket(`${process.env.REACT_APP_WS_URI}/authNow`)
    secureWs.current.onopen = (_e) =>
      setReadyState((currentState) => ({ ...currentState, secure: stateArr[secureWs.current?.readyState ?? 0] }))
    secureWs.current.onclose = (_e) =>
      setReadyState((currentState) => ({ ...currentState, secure: stateArr[secureWs.current?.readyState ?? 0] }))
    secureWs.current.onerror = (e) =>
      setReadyState((currentState) => ({ ...currentState, secure: stateArr[secureWs.current?.readyState ?? 0] }))
    secureWs.current.onmessage = (e) => {
      setMessage(e.data)
    }

    secureWs.current.onmessage = (e) => {
      setAuthMessage(e.data)
    }

    return () => {
      secureWs.current?.close()
    }
  }, [secureWs])

  useEffect(() => {
    console.log("home: " + JSON.stringify(user))
  }, [user])

  useEffect(() => {
    if (userInformation && userInformation.code !== 0 && !userInformation.user && user.loggedIn) {
      console.log(`pushing logout: userInformation: ${JSON.stringify(userInformation)} user: ${JSON.stringify(user)}`)
      history.push("/logout")
    }
  }, [userInformation, user.loggedIn, history])

  useEffect(() => {
    const interval = setInterval(() => {
      ws.current?.send("ping")
      secureWs.current?.send("ping")
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  // const userInformation: Option<User> = useGetUserInfo()

  // console.log("userInformation: " + userInformation)

  /*
    if (userInformation.isEmpty && user.email !== null) {
        history.push('/logout')
    }
    */

  const classes = useStyles()

  return (
    <Container component="main" className={classes.main} maxWidth="sm">
      <Typography variant="h2" component="h1" gutterBottom>
        Hello, {user.name ? user.name : "Stranger"}
      </Typography>

      <Typography variant="h5" component="h2" gutterBottom>
        {"This is the home. "}
        {"Here you will find everything related to our elections"}
      </Typography>

      <Box>&nbsp;</Box>

      <Box>&nbsp;</Box>

      <Typography variant="body1" component="h2" gutterBottom>
        {"Servertime status: "} {readyState.normal}
      </Typography>
      <Typography variant="body1" component="h2" gutterBottom>
        {"time: "} {message}
      </Typography>

      <Box>&nbsp;</Box>

      <Typography variant="body1" component="h2" gutterBottom>
        {"Auth Servertime: "} {readyState.secure}
      </Typography>
      <Typography variant="body1" component="h2" gutterBottom>
        {"time: "} {authMessage}
      </Typography>
    </Container>
  )
}
