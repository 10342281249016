import { Route, Redirect } from "react-router-dom"
import { RootState } from "../store/rootReducer"
import { UserState } from "../store/user/types"
import { useSelector } from "react-redux"

const PrivateRoute: React.FC<{
  component: React.FC
  path: string
  exact: boolean
  permissions: number
}> = (props) => {
  const selectUser = (state: RootState) => state.user
  const user: UserState = useSelector(selectUser)

  /* permissions:
      0: Guest / non authenticated
      1: Commercial
      2: Operations
  */
  const authorized = user.permission >= props.permissions
  console.log("authorized: " + authorized)
  return authorized ? <Route path={props.path} exact={props.exact} component={props.component} /> : <Redirect to="/" />
}
export default PrivateRoute
