import { Avatar, Button, Container, CssBaseline, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store/rootReducer"
import { UserState } from "../../store/user/types"
import { Redirect } from "react-router-dom"
import { makeStyles } from "@mui/styles"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import crypto from "crypto"
import { updateSession } from "../../store/user/actions"
import { base64URLEncode, sha256 } from "../../utils/stringUtils"
import queryString from "query-string"
import { useEffect, useState } from "react"

const useStyles = makeStyles((theme: any) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const generateRandomChallenges = () => {
  const codeState = base64URLEncode(crypto.randomBytes(32).toString("base64"))
  const codeVerifier = base64URLEncode(crypto.randomBytes(32).toString("base64"))
  const codeChallenge = base64URLEncode(sha256(codeVerifier).toString("base64"))

  return { codeState: codeState, codeVerifier: codeVerifier, codeChallenge: codeChallenge }
}

const selectUser = (state: RootState) => state.user

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Login = () => {
  const classes = useStyles()

  const user: UserState = useSelector(selectUser)
  const dispatch = useDispatch()

  const [url, setUrl] = useState<string>("")

  console.log("current state codeState: " + user.codeState)
  console.log("current state codeVerifier: " + user.codeVerifier)

  const login = async (base_url: string) => {
    const challenges = generateRandomChallenges()

    console.log("generateRandomChallenges: " + JSON.stringify(challenges))

    const query = {
      state: challenges.codeState,
      code_challenge: challenges.codeChallenge,
      code_challenge_method: "S256",
      response_type: "code",
      client_id: process.env.REACT_APP_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_REDIRECT_URI,
      scope: process.env.REACT_APP_SCOPE,
      ui_locales: ["es"],
    }

    setUrl(base_url + "?" + queryString.stringify(query))

    const updatedUser: UserState = {
      ...user,
      loggedIn: false,
      codeState: challenges.codeState,
      codeVerifier: challenges.codeVerifier,
      usedCodes: false,
    }

    console.log("updated user: " + JSON.stringify(updatedUser))
    console.log("old user: " + JSON.stringify(user))
    dispatch(updateSession(updatedUser))
    console.log("codeState: " + user.codeState)
    console.log("codeVerifier: " + user.codeVerifier)
    console.log("url: " + url)
  }

  useEffect(() => {
    if (!user.usedCodes && url) {
      console.log("useEffect: new user: " + JSON.stringify(user))
      console.log("useEffect: codeState: " + user.codeState)
      console.log("useEffect: codeVerifier: " + user.codeVerifier)
      console.log("useEffect: url: " + url)
      dispatch(updateSession({ ...user, usedCodes: true }))
      window.location.href = url
    }
  }, [user, url])

  useEffect(() => {
    console.log("useEffect: user: " + JSON.stringify(user))
  }, [user])

  if (user.loggedIn) {
    return <Redirect to="/Home" />
  } else {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Iniciar sesión con email de EVoting
          </Typography>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => login(process.env.REACT_APP_LOGIN_URL)}
          >
            Ingresar
          </Button>
        </div>
      </Container>
    )
  }
}
