import { DESTROY_SESSION, UPDATE_SESSION, UserActionTypes, UserState } from "./types"

/**
 * Define the possible actions we can perform on a user.
 * */
export function updateSession(newSession: UserState): UserActionTypes {
  return {
    type: UPDATE_SESSION,
    payload: newSession,
  }
}

export function destroySession(): UserActionTypes {
  return {
    type: DESTROY_SESSION,
  }
}
