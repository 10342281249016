import React, { useEffect, useState } from "react"
import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import CssBaseline from "@mui/material/CssBaseline"
import TextField from "@mui/material/TextField"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import Grid from "@mui/material/Grid"
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import { postEntity, useGetClientsFullName, useGetClientsShortName, useGetClientTypes, useGetElectionTypes } from "../../hooks/useQueries"
import { ClientDTO, ClientType, ElectionType, Election, IdDTO } from "../../types/types"
import { useHistory } from "react-router"
import DatePicker from "@mui/lab/DatePicker"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import { RootState } from "../../store/rootReducer"
import { UserState } from "../../store/user/types"
import { useSelector } from "react-redux"
import Autocomplete from "@mui/material/Autocomplete"
import { makeStyles } from "@mui/styles"
import esLocale from "date-fns/locale/es"
import * as constants from "./const"
import { Box } from "@mui/material"
import { timezones } from "./const"
import { useTheme } from "@mui/material/styles"
import ObjectID from "bson-objectid"

const useStyles = makeStyles(() => ({
  paper: {
    marginTop: useTheme().spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: useTheme().spacing(1),
    backgroundColor: useTheme().palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: useTheme().spacing(3),
    marginBottom: useTheme().spacing(3),
  },
  submit: {
    margin: useTheme().spacing(3, 0, 2),
  },
  formControl: {
    minWidth: "100%",
  },
  paperError: {
    marginTop: useTheme().spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}))

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const NewElections = () => {
  const classes = useStyles()
  const history = useHistory()

  const selectUser = (state: RootState) => state.user
  const user: UserState = useSelector(selectUser)

  const [electionId, setElectionId] = useState<string>()
  const [clientCompleted, setClientCompleted] = useState<boolean>(false)
  const [formCompleted, setFormCompleted] = useState<boolean>(false)

  // API Calls
  const electionTypeList: ElectionType[] = useGetElectionTypes()
  const clientTypeList: ClientType[] = useGetClientTypes()
  const clientsFullNamesList: ClientDTO[] = useGetClientsFullName()
  const clientsShortNamesList: ClientDTO[] = useGetClientsShortName()

  const [timezone, setTimezone] = useState({ name: "America/Santiago", value: "America/Santiago" })

  const saveClientFullName = async (postData: ClientDTO) => {
    const clientIdReceived = await postEntity<ClientDTO, IdDTO>(postData, process.env.REACT_APP_BACKEND_POST_CLIENTS_FULL_NAME_URL)
    console.log(`clientFullName received: ${JSON.stringify(clientIdReceived)}`)
  }

  const saveClientShortName = async (postData: ClientDTO) => {
    const clientIdReceived = await postEntity<ClientDTO, IdDTO>(postData, process.env.REACT_APP_BACKEND_POST_CLIENTS_SHORT_NAME_URL)
    console.log(`clientShortName received: ${JSON.stringify(clientIdReceived)}`)
  }

  const saveElection = async (postData: Election) => {
    const electionIdReceived = await postEntity<Election, IdDTO>(postData, process.env.REACT_APP_BACKEND_ELECTIONS_URL)
    console.log(`electionIdReceived: ${JSON.stringify(electionIdReceived)}`)
    setElectionId(electionIdReceived?.id)
  }

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      setFormValues((prevState: Election) => ({ ...prevState, voteDate: { ...prevState.voteDate, startDate: date } }))
    }
  }

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      setFormValues((prevState: Election) => ({ ...prevState, voteDate: { ...prevState.voteDate, endDate: date } }))
    }
  }
  // useStates
  const [loading, setLoading] = useState(true)
  const [electionTypes, setElectionTypes] = useState([{ name: "Cargando ...", value: "" }])
  const [clientTypes, setClientTypes] = useState([{ name: "Cargando ...", value: "" }])
  const [clientsFullName, setClientsFullName] = useState<ClientDTO[]>([])
  const [clientsShortName, setClientsShortName] = useState<ClientDTO[]>([])

  const [formValues, setFormValues] = useState<Election>({
    _id: new ObjectID(),
    clientFullName: "",
    clientShortName: "",
    electionShortName: "",
    electionType: "",
    clientType: "",
    ownerEmail: "",
    country: "",
    electoralRollSize: 0,
    assigned: false,
    state: "created",
    voteDate: {
      eventType: "voteDate",
      name: "",
      timezone: "",
      startDate: new Date(),
      endDate: new Date(new Date().setDate(new Date().getDate() + 1)), // tomorrow
      allDay: true,
    },
    creationDate: new Date(),
    timezone: "",
    inspector: false,
    notary: false,
    districts: false,
    comments: "",
  })

  useEffect(() => {
    if (electionTypeList) {
      setElectionTypes(electionTypeList.map((etype) => ({ name: etype.name, value: etype.name })))
      setLoading(false)
      console.log("electionTypes: " + JSON.stringify(electionTypeList))
    }
  }, [electionTypeList])

  useEffect(() => {
    if (clientTypeList) {
      setClientTypes(clientTypeList.map((ctype) => ({ name: ctype.name, value: ctype.name })))
      setLoading(false)
      console.log("clientTypes: " + JSON.stringify(clientTypeList))
    }
  }, [clientTypeList])

  useEffect(() => {
    console.log(`clientsFullNamesList: ${JSON.stringify(clientsFullNamesList)}`)
    if (clientsFullNamesList) {
      setClientsFullName(clientsFullNamesList)
    }
  }, [clientsFullNamesList])

  useEffect(() => {
    console.log(`clientsShortNamesList: ${JSON.stringify(clientsShortNamesList)}`)
    if (clientsShortNamesList) {
      setClientsShortName(clientsShortNamesList)
    }
  }, [clientsShortNamesList])

  const handleElectionTypeChange = (event: SelectChangeEvent) => {
    setFormValues({ ...formValues, electionType: event.target.value })
  }

  const handleClientTypeChange = (event: SelectChangeEvent) => {
    setFormValues({ ...formValues, clientType: event.target.value })
  }

  const handleCountryChange = (event: SelectChangeEvent) => {
    setFormValues({ ...formValues, country: event.target.value })
  }

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target
    console.log("handleCheckChange name : " + name + "value: " + event.target.checked)
    setFormValues({ ...formValues, [name]: event.target.checked })
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    console.log("handleInputChange name : " + name + "value: " + value)
    setFormValues({ ...formValues, [name]: value })
  }

  const handleAutoCompleteChange = (event: React.SyntheticEvent, value: string | null) => {
    setFormValues({ ...formValues, clientFullName: value || "" })
  }
  const handleAutoCompleteChangeSName = (event: React.SyntheticEvent, value: string | null) => {
    console.log(`handleAutoCompleteChangeSName: value: ${JSON.stringify(value)}`)
    setFormValues({ ...formValues, clientShortName: value || "" })
  }

  const handleTimezoneAutoCompleteChange = (event: React.SyntheticEvent, value: { name: string; value: string } | null) => {
    console.log(`setting timezone: :${JSON.stringify(value)}`)
    if (value) {
      setTimezone(value)
      setFormValues((currentValue) => ({ ...currentValue, timezone: value.value }))
    }
  }

  // Submit the form, handles all the logic
  const addElectionHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log("addElectionHandler called")

    event.preventDefault()

    if (!formValues.clientFullName) {
      alert("Nombre Cliente no puede estar vacío")
    } else if (!formValues.clientShortName) {
      alert("Nombre corto de cliente no puede estar vacío")
    } else if (!formValues.electionShortName) {
      alert("Nombre de la votación no puede estar vacío")
    } else if (!formValues.electionType) {
      alert("Tipo de la votación no puede estar vacío")
    } else if (isNaN(formValues.electoralRollSize)) {
      alert("Tamaño del padrón no es numérico")
    } else if (formValues.electoralRollSize < 0) {
      alert("El tamaño del padrón no puede ser negativo")
    } else if (
      formValues.voteDate.startDate &&
      formValues.voteDate.endDate &&
      formValues.voteDate.startDate > formValues.voteDate.endDate
    ) {
      alert("La fecha de inicio no puede ser mayor a la fecha de término")
    } else {
      // We can submit the  form

      saveClientFullName({
        _id: new ObjectID(),
        name: formValues.clientFullName,
        creationDate: new Date().toISOString(),
      })

      saveClientShortName({
        _id: new ObjectID(),
        name: formValues.clientShortName,
        creationDate: new Date().toISOString(),
      })

      setClientCompleted(true) // trigger the useEffect call
    }
  }

  useEffect(() => {
    console.log(`saving... saveElection`)
    if (clientCompleted && user.email) {
      console.log(`saving... saveElection: true`)
      saveElection({
        _id: new ObjectID(),
        clientFullName: formValues.clientFullName,
        clientShortName: formValues.clientShortName,
        ownerEmail: user.email,
        electionShortName: formValues.electionShortName,
        electionType: formValues.electionType,
        clientType: formValues.clientType,
        country: formValues.country,
        electoralRollSize: Number(formValues.electoralRollSize),
        assigned: false,
        creationDate: new Date(),
        state: "created",
        voteDate: {
          eventType: "voteDate",
          name: formValues.electionShortName,
          timezone: "",
          startDate: formValues.voteDate.startDate,
          endDate: formValues.voteDate.endDate,
          allDay: true,
        },
        inspector: formValues.inspector,
        notary: formValues.notary,
        districts: formValues.districts,
        timezone: "America/Santiago",
        ipLimit: 0,
        comments: formValues.comments,
      })
    }
  }, [clientCompleted, formValues, user])

  useEffect(() => {
    // Election was saved
    if (formCompleted) {
      if (electionId) {
        history.push("/elections/complete-election/" + electionId)
      }
    } else {
      if (electionId) {
        history.push("/elections/list")
      }
    }
  }, [electionId])

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <CreateOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h3">
          Nueva votación
        </Typography>
        <div className={classes.paperError} />
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Autocomplete
                id="autocompleteName"
                freeSolo
                onChange={handleAutoCompleteChange}
                options={[...new Set(clientsFullName.map((option) => option.name))]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoComplete="ename"
                    label="Nombre oficial organización"
                    id="clientName"
                    required
                    variant="outlined"
                    name="clientName"
                    autoFocus
                    fullWidth
                    value={formValues.clientFullName}
                    onChange={handleInputChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Autocomplete
                id="autocompleteShortName"
                freeSolo
                onChange={handleAutoCompleteChangeSName}
                options={[...new Set(clientsShortName.map((option) => option.name))]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoComplete="sname"
                    name="clientShortName"
                    variant="outlined"
                    required
                    fullWidth
                    id="clientShortName"
                    label="Nombre abreviado de cliente"
                    value={formValues.clientShortName}
                    onChange={handleInputChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                autoComplete="vname"
                name="electionShortName"
                variant="outlined"
                required
                fullWidth
                id="electionShortName"
                label="Nombre corto votación"
                value={formValues.electionShortName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel id="typeElectionLabel">Tipo Votación</InputLabel>
                <Select
                  labelId="typeElectionLabel"
                  id="typeElection"
                  disabled={loading}
                  label="Tipo Votación"
                  value={formValues.electionType}
                  onChange={handleElectionTypeChange}
                >
                  {electionTypes.map((item) => (
                    <MenuItem key={item.value + item.name} value={item.value}>
                      {" "}
                      {item.name}{" "}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel id="typeClientLabel">Tipo Cliente</InputLabel>
                <Select
                  labelId="typeClientLabel"
                  id="typeClient"
                  disabled={loading}
                  label="Tipo Cliente"
                  value={formValues.clientType}
                  onChange={handleClientTypeChange}
                >
                  {clientTypes.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {" "}
                      {item.name}{" "}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel id="countryLabel">País</InputLabel>
                <Select
                  labelId="country"
                  id="country"
                  disabled={loading}
                  label="País"
                  value={formValues.country}
                  onChange={handleCountryChange}
                >
                  {constants.countries.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {" "}
                      {item.name}{" "}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <Autocomplete
                  id="autoTimezone"
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  onChange={handleTimezoneAutoCompleteChange}
                  value={timezone}
                  renderInput={(params) => (
                    <TextField {...params} name="timezone" variant="outlined" fullWidth id="timezone" label="Huso horario" autoFocus />
                  )}
                  options={timezones}
                />
              </FormControl>
            </Grid>
            <Grid container item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                  <DatePicker
                    label="Fecha inicio"
                    value={formValues.voteDate.startDate}
                    onChange={handleStartDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                  <DatePicker
                    label="Fecha fin"
                    value={formValues.voteDate.endDate}
                    onChange={handleEndDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <TextField
                  autoComplete="sizeElectoralRoll"
                  name="electoralRollSize"
                  variant="outlined"
                  required
                  fullWidth
                  id="electoralRollSize"
                  label="Tamaño Padrón"
                  value={formValues.electoralRollSize}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} />
            <Grid item justifyContent="center" xs={6}>
              <FormControlLabel
                control={<Checkbox checked={formValues.inspector} color="primary" onChange={handleCheckChange} name="inspector" />}
                label="Inspección del Trabajo"
              />
            </Grid>

            <Grid item justifyContent="center" xs={3}>
              <FormControlLabel
                control={<Checkbox checked={formValues.notary} color="primary" onChange={handleCheckChange} name="notary" />}
                label="Notario"
              />
            </Grid>

            <Grid item justifyContent="center" xs={3}>
              <FormControlLabel
                control={<Checkbox checked={formValues.districts} color="primary" onChange={handleCheckChange} name="districts" />}
                label="Distritos"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <TextField
                  autoComplete="comments"
                  multiline
                  rows={4}
                  name="comments"
                  fullWidth
                  id="comments"
                  label="Observaciones"
                  value={formValues.comments}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box>&nbsp;</Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button type="submit" fullWidth variant="contained" color="primary" onClick={addElectionHandler} className={classes.submit}>
                AGREGAR
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  )
}
