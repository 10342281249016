import { useEffect, useState } from "react"
import { ClientDTO, ClientType, Election, ElectionType, IdTokenDTO, TokenDTO, User, UserRequest, Operator } from "../types/types"
import { get } from "../fetchers/fetchers"
import axios from "axios"
import * as queryString from "querystring"

export const useGetElectionTypes: () => ElectionType[] = () => {
  const [data, setData] = useState<ElectionType[]>([])
  const getData = async () => {
    const results = await get<ElectionType[]>(process.env.REACT_APP_ELECTION_TYPES_URL).catch(() => [])
    setData(results)
  }

  useEffect(() => {
    getData()
  }, [])

  return data
}

export const useGetClientTypes: () => ClientType[] = () => {
  const [data, setData] = useState<ClientType[]>([])
  const getData = async () => {
    const results = await get<ClientType[]>(process.env.REACT_APP_CLIENT_TYPES_URL).catch(() => [])
    setData(results)
  }

  useEffect(() => {
    getData()
  }, [])

  return data
}

export const useGetOperators: () => Operator[] = () => {
  const [data, setData] = useState<Operator[]>([])
  const getData = async () => {
    const results = await get<Operator[]>(process.env.REACT_APP_OPERATORS_URL).catch((err) => [])
    setData(results)
  }

  useEffect(() => {
    getData()
  }, [])

  return data
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePostToken = (postData: TokenDTO) => {
  const [token, setToken] = useState<IdTokenDTO>()
  const getToken = async () => {
    console.log("posting to : " + process.env.REACT_APP_TOKEN_URL + " data: " + queryString.stringify(postData))
    const tokenReceived = await axios
      .post<IdTokenDTO>(process.env.REACT_APP_TOKEN_URL, queryString.stringify(postData), {
        headers: { "content-type": "application/x-www-form-urlencoded;charset=utf-8" },
      })
      .then(({ data }) => data)
      .catch((err) => {
        console.log("error: " + err)
        return undefined
      })
    setToken(tokenReceived)
  }

  useEffect(() => {
    getToken()
  }, [])

  return token
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetUserInfo = () => {
  const [user, setUser] = useState<UserRequest>()
  const getUser = async () => {
    const userResult = await get<User>(process.env.REACT_APP_VERIFY_SESSION_URL)
      .then((u) => {
        return { user: u, code: 200 }
      })
      .catch((err) => {
        console.log("error:  " + err)
        return undefined
      })
    setUser(userResult)
  }

  useEffect(() => {
    getUser()
  }, [])

  return user
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetElections = () => {
  const [elections, setElections] = useState<Election[]>([])
  const getData = async () => {
    const results = await get<Election[]>(process.env.REACT_APP_BACKEND_ELECTIONS_URL).catch(() => [])
    setElections(results)
  }

  useEffect(() => {
    getData()
  }, [])
  //console.log("getElections: " + JSON.stringify(elections))
  return elections
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetElection = (electionId: string) => {
  const [election, setElection] = useState<Election>()
  const getData = async () => {
    await get<Election>(process.env.REACT_APP_BACKEND_ELECTION_URL + "/" + electionId)
      .then((results) => {
        console.log(`elecction recibida ${JSON.stringify(results)}`)
        setElection(results)
      })
      .catch((error) => {
        console.log(`useGetElection error: ${error}`)
      })
  }

  useEffect(() => {
    getData()
  }, [])
  return election
}

export async function postEntity<T, R>(postData: T, path: string): Promise<R | undefined> {
  console.log("postEntity data: " + JSON.stringify(postData))
  return await axios
    .post<R>(path, postData, { withCredentials: true })
    .then(({ data }) => {
      console.log(`data: ${JSON.stringify(data)}`)
      return data
    })
    .catch((err) => {
      console.log("postEntity: error " + err)
      console.log("process: " + path)
      return undefined
    })
}

export async function deleteEntity<T, R>(payload: T, path: string): Promise<R | undefined> {
  console.log("deleting payload: " + JSON.stringify(payload))
  return await axios
    .delete(path, { data: payload, withCredentials: true })
    .then(({ data }) => {
      console.log(`data delete: ${JSON.stringify(data)}`)
      return data
    })
    .catch((err) => {
      console.log("deleteEntity: error " + err)
      console.log("process: " + path)
      return undefined
    })
}

export const useGetClientsFullName: () => ClientDTO[] = () => {
  const [data, setData] = useState<ClientDTO[]>([])
  const getData = async () => {
    const results = await get<ClientDTO[]>(process.env.REACT_APP_BACKEND_GET_CLIENTS_FULL_NAME_URL).catch(() => [])
    setData(results)
  }

  useEffect(() => {
    getData()
  }, [])

  return data
}

export const useGetClientsShortName: () => ClientDTO[] = () => {
  const [data, setData] = useState<ClientDTO[]>([])
  const getData = async () => {
    const results = await get<ClientDTO[]>(process.env.REACT_APP_BACKEND_GET_CLIENTS_SHORT_NAME_URL).catch(() => [])
    setData(results)
  }

  useEffect(() => {
    getData()
  }, [])

  return data
}
